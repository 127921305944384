import RenderApp from "containers/Appv4";
import ServiceFactory, { Services } from "containers/ServiceFactory";
import { APP_ACP } from "core/consts";
import Router from "./Router";

export type AcpContext = ReturnType<typeof Context>;

const Context = (services: Services) => ({
  app: APP_ACP,
  services,
});

const services = ServiceFactory("ACP");
const context = Context(services);

RenderApp(context, Router);
