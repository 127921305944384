import { ONBOARDING_PAGE_WELCOME, TRACK_EVENTS } from "core/consts";
import { getQueryVariable, useIntParams } from "core/model/utils/urls";
import React, { ReactNode, useCallback, useContext, useState } from "react";
import {
  NavigateFunction,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import Stepper from "./components/Stepper";
import { OnboardingPageWrapper, OnboardingWrapper } from "./pages/shared";
import { STEPPER_STEPS, STEPS, Step, getStepperSteps } from "./steps";

function inStepper(step: Step): boolean {
  if (!step) return false;
  return (STEPPER_STEPS as Array<Step>).includes(step);
}
const goToStep =
  ({
    baseUrl,
    navigate,
    token,
  }: {
    baseUrl: string;
    navigate: NavigateFunction;
    token: string;
  }) =>
  (stepIndex?: number) => {
    const tokenVar = `?token=${token}`;

    if (!stepIndex) {
      return navigate(`${baseUrl}${tokenVar}`);
    }

    return navigate(`${baseUrl}/${STEPS[stepIndex]}${tokenVar}`);
  };

const resetPasswordParams = ({
  navigate,
  search,
}: {
  navigate: NavigateFunction;
  search: URLSearchParams;
}) => {
  search.delete("reset");
  search.delete("password_token");
  return navigate(
    {
      search: search.toString(),
    },
    { replace: true },
  );
};

type OnboardingContextType = {
  careproviderId: number;
  careproviderToken: string;
  currentStep: string;
  getFormInputValue: () => AnyObject;
  goTo: (step: Step) => void;
  isResetPasswordPage: boolean;
  next: () => void;
  passwordToken?: string;
  previous: () => void;
  reset: () => void;
  resetPasswordPage: () => void;
  saveFormInputValue: (inputValue: AnyObject) => void;
  steps: Step[];
};

const defaultState = {
  currentStep: "",
  current: -1,
  careproviderId: -1,
  careproviderToken: "",
  steps: STEPS,
  isResetPasswordPage: false,
  getFormInputValue: () => ({}),
  saveFormInputValue: (inputValue: AnyObject) => inputValue,
  reset: () => {},
  resetPasswordPage: () => {},
  setCurrent: (step: number) => step,
  next: () => {},
  previous: () => {},
  goTo: (step: Step) => step,
};

const OnboardingContext =
  React.createContext<OnboardingContextType>(defaultState);

/**
 *
 * @returns the OnboardingContext, including the tokens for Onboarding Password Reset Page
 */
export const useOnboarding = () => useContext(OnboardingContext);

export default function NewCareproviderOnboarding({
  children,
}: {
  children?: ReactNode;
}) {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { careproviderId } = useIntParams<{ careproviderId: number }>({
    careproviderId: -1,
  });
  const { step = ONBOARDING_PAGE_WELCOME } = useParams<{ step: Step }>();
  const currentStepIndex = STEPS.indexOf(step as Step);
  const baseUrl = `/careprovider/newonboarding/${careproviderId}`;
  const token = getQueryVariable(location.search, "token") as string;
  const passwordToken = getQueryVariable(location.search, "password_token");
  const { trackEvent } = useTracking();
  const translations = useTranslations();

  const isResetPage = Boolean(search.get("reset"));

  const [formInputValues, setFormInputValues] =
    useState<AnyObject>(defaultState);

  const navigateTo = goToStep({
    baseUrl,
    navigate,
    token,
  });

  const showStepper = inStepper(step as Step)
    ? step == "password" && !passwordToken
      ? false
      : true
    : false;

  const currentStep = `(${translations.newOnboardingTwo.stepper.step} ${currentStepIndex}/5)`;

  return (
    <OnboardingContext.Provider
      value={{
        currentStep,
        careproviderId,
        careproviderToken: token,
        passwordToken,
        steps: STEPS,
        getFormInputValue: () => formInputValues[step],
        saveFormInputValue: (inputValue: AnyObject) =>
          setFormInputValues({
            ...formInputValues,
            [step]: inputValue,
          }),
        reset: () => navigateTo(),
        next: () => {
          const destination = currentStepIndex + 1;
          trackEvent({
            name: TRACK_EVENTS.NEW_ONBOARDING,
            action: "next",
            from: STEPS[currentStepIndex],
            to: STEPS[destination],
            distinct_id: careproviderId,
          });
          navigateTo(destination);
        },
        previous: () => {
          const destination = currentStepIndex - 1;
          trackEvent({
            name: TRACK_EVENTS.NEW_ONBOARDING,
            action: "back",
            from: STEPS[currentStepIndex],
            to: STEPS[destination],
            distinct_id: careproviderId,
          });

          navigateTo(destination);
        },
        resetPasswordPage: () => {
          resetPasswordParams({ navigate, search });
        },
        isResetPasswordPage: isResetPage,
        goTo: useCallback((step: Step) => {
          const destination = STEPS.indexOf(step);
          trackEvent({
            name: TRACK_EVENTS.NEW_ONBOARDING,
            action: "goTo",
            from: STEPS[currentStepIndex],
            to: STEPS[destination],
            distinct_id: careproviderId,
          });

          navigateTo(destination);
        }, []),
      }}
    >
      <OnboardingWrapper>
        <Stepper
          activeStep={step}
          steps={getStepperSteps(translations)}
          showSteps={showStepper}
        />
        <OnboardingPageWrapper>
          {children ? children : <Outlet />}
        </OnboardingPageWrapper>
      </OnboardingWrapper>
    </OnboardingContext.Provider>
  );
}
