import { APP_BAR_HEIGHT_V2 } from "core/consts";
import { createContext, useContext, useState } from "react";

type NavbarHeightContextType = {
  height: number;
  setHeight: (height: number) => void;
};

const NavbarHeightContext = createContext<NavbarHeightContextType>({
  setHeight: () => {},
  height: APP_BAR_HEIGHT_V2,
});

export const useNavbarHeightContext = () => useContext(NavbarHeightContext);

export function NavbarHeightProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [height, setHeight] = useState<number>(0);

  return (
    <NavbarHeightContext.Provider value={{ height, setHeight }}>
      {children}
    </NavbarHeightContext.Provider>
  );
}
