import { dp, sizing } from "ds_legacy/materials/metrics";
import React from "react";
import styled from "styled-components";

export const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${dp(70)};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Positioner = styled.div`
  position: absolute;
  bottom: ${sizing(3)};
  left: 0;
  right: 0;
`;

export const BottomWrapper = ({ children }: { children: React.ReactNode }) => (
  <FlexContainer>
    <Positioner>{children}</Positioner>
  </FlexContainer>
);
