import MuiTab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import { Theme, useTheme } from "@mui/material/styles";
import { styled as muiStyled } from "@mui/system";
import { useEnvContext } from "context/EnvContext";
import { APP_PROVIDER_SEARCH, MESSENGER_TAB_INFO } from "core/consts";
import { isDesignBatch1 } from "core/model/utils/featureFlags";
import { MessengerTabKinds, TourElement } from "core/types";
import { Dot } from "ds_legacy/components/Dot";
import {
  ACCENT_COLOR,
  CARD_BORDER,
  GREY_100,
  GREY_600,
} from "ds_legacy/materials/colors";
import {
  APP_BAR_HEIGHT,
  APP_BAR_PROVIDERSEARCH_HEIGHT,
  TABS_HEIGHT,
  TABS_HEIGHT_MOBILE,
  Z_INDEX_APP_BAR,
  border,
  dp,
  margin,
  padding,
} from "ds_legacy/materials/metrics";
import { shadow3dp } from "ds_legacy/materials/shadows";
import { useNavbarHeightContext } from "dsl/atoms/Navbar/Context/NavbarHeightContext";
import {
  CSSProperties,
  ChangeEvent,
  Fragment,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

export type MessengerTabsProps = {
  activeTab: MessengerTabKinds | null;
  isTablet?: boolean;
  position?: CSSProperties["position"];
  tabs: {
    component: ReactElement;
    key: MessengerTabKinds;
    label: string;
    onClick?: (...args: any) => void;
    tour?: TourElement;
    withDot?: boolean;
  }[];
};

type TabStyleProps = { isTablet?: boolean; theme: Theme };

const NOT_FOUND = -1;
const DEFAULT_TAB = 0;

export const TabContentWrapper = styled.div<{
  height?: CSSProperties["height"];
  navbarHeight?: number;
  overflowY?: CSSProperties["overflowY"];
}>`
  box-sizing: border-box;
  padding: ${padding(1)};
  width: 100%;
  overflow-y: ${({ overflowY }) => overflowY || "visible"};
  height: ${({ height }) => height || "100%"};
  margin-top: ${({ navbarHeight }) => (navbarHeight ? dp(navbarHeight) : "")};
  & > * + * {
    margin: ${margin(4, 0, 0, 0)};
  }
`;

const getTabsHeight = (isTablet: TabStyleProps["isTablet"]) => ({
  minHeight: `${isTablet ? TABS_HEIGHT_MOBILE : TABS_HEIGHT} !important`, // reset min-height material-ui
  height: isTablet ? TABS_HEIGHT_MOBILE : TABS_HEIGHT,
});

export function useMessengerTabs() {
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] =
    useState<MessengerTabKinds>(MESSENGER_TAB_INFO);

  useEffect(() => {
    const searchParamTab = searchParams.get("activeTab");
    if (searchParamTab && activeTab !== searchParamTab) {
      setActiveTab(searchParamTab as MessengerTabKinds);
    }
  }, [searchParams.get("activeTab")]);

  return { activeTab, setActiveTab };
}

const getBorderStyle = (
  isTablet: boolean,
): {
  border: CSSProperties["border"];
  borderBottom?: CSSProperties["borderBottom"];
  margin?: CSSProperties["margin"];
} => {
  if (!isTablet) {
    return {
      border: border({ color: CARD_BORDER }),
    };
  }

  return { border: undefined };
};

const StyledTabs = muiStyled(MuiTabs, {
  shouldForwardProp: (prop) =>
    !["isTablet", "activeTab", "position", "appBarHeight"].includes(
      prop as string,
    ),
})<{
  activeTab: MessengerTabsProps["activeTab"];
  appBarHeight: number;
  isTablet: boolean | undefined;
  position?: CSSProperties["position"];
}>(({ appBarHeight, isTablet, position, theme }) => ({
  ...getTabsHeight(isTablet),
  background: isTablet ? theme.palette.primary.dark : GREY_100,
  boxShadow: isTablet ? shadow3dp : "none",
  boxSizing: "border-box",
  maxWidth: "100vw",
  position: position || isTablet ? "fixed" : "static",
  top: position === "static" ? 0 : isTablet ? dp(appBarHeight) : undefined,
  width: isTablet ? "100%" : "inherit",
  zIndex: Z_INDEX_APP_BAR - 1,
  ...getBorderStyle(!!isTablet),
  "&.MuiTabs-root": {
    overflow: "visible",
  },
  ".MuiTabs-scroller": {
    marginBottom: "-1px !important",
  },
}));

const StyledTab = muiStyled(MuiTab, {
  shouldForwardProp: (prop) => !["isTablet"].includes(prop as string),
})<{ isTablet: boolean | undefined }>(({ isTablet, theme }) => ({
  ...getTabsHeight(isTablet),
  textTransform: "capitalize",
  color: isTablet ? theme.palette.common.white : GREY_600,
  "&.MuiTab-labelIcon": {
    paddingTop: `${dp(6)} !important`,
  },
  "&.Mui-selected": {
    fontWeight: "bold !important",
    color: isTablet ? theme.palette.common.white : theme.palette.primary.main,
    background: isTablet ? "transparent" : theme.palette.common.white,
  },
  "&.MuiTab-iconWrapper": {
    display: "flex",
    flexDirection: "row-reverse !important" as CSSProperties["flexDirection"], // needed due to https://github.com/cssinjs/jss/issues/1344
  },
}));

export function MessengerTabs({
  activeTab,
  tabs = [],
  isTablet,
  position,
}: MessengerTabsProps) {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabIndex = tabs.findIndex((tab) => tab.key === activeTab); // findIndex returns -1, if not found
  const [selectedTab, setSelectedTab] = useState(
    activeTabIndex === NOT_FOUND ? DEFAULT_TAB : activeTabIndex,
  );
  const { app } = useEnvContext();
  const { height } = useNavbarHeightContext();

  const handleTabChange = (event: ChangeEvent<AnyObject>, newValue: number) => {
    const activeTab = tabs[newValue].key;
    if (activeTab) {
      searchParams.set("activeTab", activeTab);
      setSearchParams(searchParams);
    }
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (selectedTab !== activeTabIndex) {
      setSelectedTab(
        activeTabIndex === NOT_FOUND ? DEFAULT_TAB : activeTabIndex,
      );
    }
  }, [activeTabIndex]);

  return (
    <>
      <StyledTabs
        appBarHeight={
          app === APP_PROVIDER_SEARCH
            ? APP_BAR_PROVIDERSEARCH_HEIGHT
            : isDesignBatch1()
            ? height
            : APP_BAR_HEIGHT
        }
        activeTab={activeTab}
        centered
        isTablet={isTablet}
        onChange={handleTabChange}
        position={position}
        TabIndicatorProps={{
          style: {
            background: isTablet
              ? theme.palette.common.white
              : theme.palette.primary.main,
          },
        }}
        textColor="primary"
        value={selectedTab}
        variant="fullWidth"
      >
        {tabs.map(({ key, label, onClick, tour, withDot }, index) => (
          <StyledTab
            disabled={tabs.length === 1}
            disableRipple
            isTablet={isTablet}
            key={index}
            onClick={onClick}
            label={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {label}
              </div>
            }
            icon={
              withDot ? (
                <Dot
                  color={ACCENT_COLOR}
                  margin={margin(0, 0, 0, 1)}
                  padding={padding(0.5)}
                />
              ) : undefined
            }
            iconPosition="end"
            data-testid={`${key}-tab`}
            {...tour}
          />
        ))}
      </StyledTabs>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minHeight: 0,
          overflow: "visible",
          padding: isTablet ? `${TABS_HEIGHT_MOBILE} 0 0 0` : "auto",
          borderTop: "0px",
        }}
      >
        {tabs.map(
          ({ component }, index) =>
            selectedTab === index && (
              <Fragment key={index}>{component}</Fragment>
            ),
        )}
      </div>
    </>
  );
}
