import { APP_ACP } from "core/consts";
import { ENV_PRODUCTION } from "core/model/config";
import { isDev } from "core/model/utils/featureFlags";
import { getBaseUrl, pathToUrl } from "core/model/utils/urls";
import { Location, NavigateFunction } from "react-router-dom";

const dashboardRoute = "/acp/dashboard";

export type AcpUrls = ReturnType<typeof getAcpHandlerUrls>;

export const getAcpHandlerUrls = ({ location }: { location: Location }) => {
  const { search } = location;

  return {
    goToProductionEnv() {
      return getBaseUrl({ env: ENV_PRODUCTION, isDev, app: APP_ACP });
    },
    goToForgotPassword() {
      return pathToUrl({
        pathname: "/acp/forgot-password",
        search,
      });
    },
    goToLogin() {
      return pathToUrl({
        pathname: "/acp/auth",
        search,
      });
    },
    goToDashboard() {
      return pathToUrl({
        pathname: `${dashboardRoute}/`,
        search,
      });
    },
    goToInternalAccounts() {
      return pathToUrl({
        pathname: `${dashboardRoute}/staff`,
        search,
      });
    },
    goToSearch() {
      return pathToUrl({
        pathname: `${dashboardRoute}/search`,
        search,
      });
    },
    goToSealdDebug(page?: string) {
      return pathToUrl({
        pathname: page
          ? `${dashboardRoute}/sealddebug/${page}`
          : `${dashboardRoute}/sealddebug/account`,
        search: "",
      });
    },
    goToInsurance(id: number | null) {
      return pathToUrl({
        pathname: id
          ? `${dashboardRoute}/insurance/${id}`
          : `${dashboardRoute}/insurance`,
        search,
      });
    },
    goToAccount(id: number) {
      return pathToUrl({
        pathname: `${dashboardRoute}/account/${id}`,
        search,
      });
    },
    goToAccountSlug(id: number, accountSlug: string) {
      return pathToUrl({
        pathname: `${dashboardRoute}/account/${id}/${accountSlug}`,
        search,
      });
    },
    goToCareseeker(id: number) {
      return pathToUrl({
        pathname: `${dashboardRoute}/careseeker/${id}`,
        search,
      });
    },
    goToCompany(id: number) {
      return pathToUrl({
        pathname: `${dashboardRoute}/company/${id}`,
        search,
      });
    },
    goToCareseekerSlug(id: number, careseekerSlug: string) {
      return pathToUrl({
        pathname: `${dashboardRoute}/careseeker/${id}/${careseekerSlug}`,
        search,
      });
    },
    goToCareproviderPage(id: number, page: string) {
      return pathToUrl({
        pathname: `${dashboardRoute}/careprovider/${id}/${page}`,
        search,
      });
    },
    goToTranslations() {
      return pathToUrl({
        pathname: `${dashboardRoute}/translations`,
        search,
      });
    },
    goToEmailTranslations() {
      return pathToUrl({
        pathname: `${dashboardRoute}/emailtranslations`,
        search,
      });
    },
    goToNativeTranslations() {
      return pathToUrl({
        pathname: `${dashboardRoute}/nativetranslations`,
        search,
      });
    },
    goToSendTestEmail() {
      return pathToUrl({
        pathname: `${dashboardRoute}/testemail`,
        search,
      });
    },
    goToCreateCareprovider() {
      return pathToUrl({
        pathname: `${dashboardRoute}/create/careprovider`,
        search,
      });
    },
    goToCreateCareseeker() {
      return pathToUrl({
        pathname: `${dashboardRoute}/create/careseeker`,
        search,
      });
    },
    goToConsultant(id: number) {
      return pathToUrl({
        pathname: `${dashboardRoute}/consultant/${id}`,
        search,
      });
    },
    goToCreateConsultant() {
      return pathToUrl({
        pathname: `${dashboardRoute}/create/consultant`,
        search,
      });
    },
    goToManuallyAddedProviders() {
      return pathToUrl({
        pathname: `${dashboardRoute}/manuallyadded`,
        search,
      });
    },
  };
};

type AcpNavigation = {
  [K in keyof AcpUrls]: (...args: Parameters<AcpUrls[K]>) => void;
};

export const getAcpNavigationHandlers = ({
  navigate,
  urls,
}: {
  navigate: NavigateFunction;
  urls: AcpUrls;
}): AcpNavigation => ({
  goToProductionEnv() {
    navigate(urls.goToForgotPassword());
  },
  goToForgotPassword() {
    navigate(urls.goToForgotPassword());
  },
  goToLogin() {
    navigate(urls.goToLogin());
  },
  goToDashboard() {
    navigate(urls.goToDashboard());
  },
  goToInternalAccounts() {
    navigate(urls.goToInternalAccounts());
  },
  goToSearch() {
    navigate(urls.goToSearch());
  },
  goToSealdDebug(page?: string) {
    navigate(urls.goToSealdDebug(page));
  },
  goToInsurance(id: number | null) {
    navigate(urls.goToInsurance(id));
  },
  goToAccount(id: number) {
    navigate(urls.goToAccount(id));
    window.scrollTo(0, 0);
  },
  goToAccountSlug(id: number, accountSlug: string) {
    navigate(urls.goToAccountSlug(id, accountSlug));
  },
  goToCareseeker(id: number) {
    navigate(urls.goToCareseeker(id));
  },
  goToCompany(id: number) {
    navigate(urls.goToCompany(id));
  },
  goToCareseekerSlug(id: number, careseekerSlug: string) {
    navigate(urls.goToCareseekerSlug(id, careseekerSlug));
  },
  goToCareproviderPage(id: number, page: string) {
    navigate(urls.goToCareproviderPage(id, page));
  },
  goToTranslations() {
    navigate(urls.goToTranslations());
  },
  goToEmailTranslations() {
    navigate(urls.goToEmailTranslations());
  },
  goToNativeTranslations() {
    navigate(urls.goToNativeTranslations());
  },
  goToSendTestEmail() {
    navigate(urls.goToSendTestEmail());
  },
  goToCreateCareprovider() {
    navigate(urls.goToCreateCareprovider());
  },
  goToCreateCareseeker() {
    navigate(urls.goToCreateCareseeker());
  },
  goToConsultant(id: number) {
    navigate(urls.goToConsultant(id));
  },
  goToCreateConsultant() {
    navigate(urls.goToCreateConsultant());
  },
  goToManuallyAddedProviders() {
    navigate(urls.goToManuallyAddedProviders());
  },
});
