import {
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
} from "core/consts";
import { SearchType } from "core/types";
import { useCallback } from "react";
import { useCountry } from "reduxentities/selectors/hooks";
import { useTranslations } from "translations";
import { OntologyType } from ".";
import { getOntologies, getOntologiesForSelect, getOntology } from "./utils";

export const useGetOntology = () => {
  const country = useCountry();
  const translations = useTranslations();
  return ({
    key,
    type,
    useShortValues,
  }: {
    key: number | null | undefined;
    type: OntologyType;
    useShortValues?: boolean;
  }) => getOntology(type, key, translations, country, useShortValues);
};

/**
 * @returns an array of ontologies formatted for MUI Select component
 */
export const useGetOntologiesForSelect = ({
  sort = false,
  type,
}: {
  sort?: boolean;
  type: OntologyType;
}) => {
  const translations = useTranslations();

  return getOntologiesForSelect(type, sort, translations);
};

export const useGetOntologies = () => {
  const translations = useTranslations();

  return ({ sort = false, type }: { sort?: boolean; type: OntologyType }) =>
    getOntologies(type, sort, translations);
};

export type ReceiverTypes =
  | "receiverTypePlural"
  | "receiverTypePluralPreferred"
  | "receiverTypeSingular"
  | "receiverTypeSingularPreferred";

export type GetTranslationForSearchTypeArgs = {
  receiverType: ReceiverTypes;
  searchType: SearchType | null | undefined;
  translationKey: string;
};

export const useGetTranslationForSearchType = () => {
  const translations = useTranslations();
  const getOntology = useGetOntology();

  const getTranslationForSearchType = useCallback(
    ({
      receiverType,
      searchType,
      translationKey,
    }: GetTranslationForSearchTypeArgs) => {
      if (
        !searchType ||
        ![
          SEARCH_TYPE_CARE,
          SEARCH_TYPE_HOSPITAL,
          SEARCH_TYPE_REHABILITATION,
          SEARCH_TYPE_TRANSPORT,
          SEARCH_TYPE_MEDICAL_SUPPLIES,
          SEARCH_TYPE_HOME_CARE,
        ].includes(searchType)
      ) {
        searchType = SEARCH_TYPE_CARE;
      }
      try {
        const receiverTypeOntology = getOntology({
          type: receiverType,
          key: searchType,
        });

        const keys = translationKey.split(".");

        let translationObj: any = translations;

        keys.forEach((key: string) => {
          translationObj = translationObj[key];
        });

        return translationObj({ [receiverType]: receiverTypeOntology });
      } catch (error) {
        console.error(
          `Failed to return translation for key ${translationKey} searchType ${searchType}`,
          error,
        );
      }
    },
    [translations, getOntology],
  );

  return getTranslationForSearchType;
};

export function TranslationForSearchType({
  receiverType,
  searchType,
  translationKey,
}: {
  receiverType: ReceiverTypes;
  searchType: SearchType;
  translationKey: string;
}) {
  const getTranslationForSearchType = useGetTranslationForSearchType();
  const translation = getTranslationForSearchType({
    translationKey,
    receiverType,
    searchType,
  });

  return translation;
}
