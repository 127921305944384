import { capitaliseFirst } from "core/model/utils/strings";
import StepMenu, { StepMenuContainer } from "ds_legacy/components/StepMenu";
import Step from "ds_legacy/components/StepMenu/Step";
import { useAcpNavigationHandlers } from "dsl/hooks";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import AccountPage from "./AccountPage";
import GroupPage from "./GroupPage";
import SessionPage from "./SessionPage";
import { PageWrapper } from "./shared";

const StepsContainer = styled(StepMenuContainer)`
  justify-content: center;
`;

const STEPS = ["account", "sender", "receiver", "patient", "request", "file"];
const DEFAULT_PAGE = STEPS[0];

export default function AcpSealdDebug() {
  const navigationHandlers = useAcpNavigationHandlers();
  const { page = DEFAULT_PAGE } = useParams<{ page: string }>();
  const activeStep = STEPS.findIndex((step) => step === page);

  useEffect(() => {
    if (activeStep === -1) {
      navigationHandlers.goToSealdDebug(DEFAULT_PAGE);
    }
  }, [activeStep, page]);

  const StepPage = useMemo(() => {
    if (activeStep === -1) return null;
    let PageComponent;
    switch (page) {
      case "account":
        PageComponent = () => <AccountPage />;
        break;
      case "sender":
      case "receiver":
        PageComponent = () => <GroupPage page={page} />;
        break;
      case "request":
      case "patient":
      case "file":
        PageComponent = () => <SessionPage page={page} />;
        break;
      default:
        return null;
    }
    return (
      <PageWrapper page={page}>
        <PageComponent />
      </PageWrapper>
    );
  }, [activeStep, page]);

  return (
    <>
      <StepsContainer>
        <StepMenu
          activeStep={activeStep}
          steps={STEPS}
          renderStep={(step, index) => (
            <Step
              active={index === activeStep}
              onClick={() => {
                navigationHandlers.goToSealdDebug(step);
              }}
            >
              {capitaliseFirst(step)}
            </Step>
          )}
        />
      </StepsContainer>
      {StepPage}
    </>
  );
}
