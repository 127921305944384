import { getLoginArguments } from "apollo/mutations/login";
import {
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  TRACK_EVENTS,
} from "core/consts";
import api from "core/model/api";
import { QueryProgressActive } from "core/types";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTracking } from "react-tracking";
import { useLocale, useTranslations } from "translations";
import { getTimestamp } from "../Careseeker/UserManagementPage/useUserManagementFeature";

export const useChangePassword = ({
  careproviderToken,
  email,
  isActivation,
  password,
  resetPasswordPage,
  resetToken,
}: {
  careproviderToken: string;
  email: string | undefined;
  isActivation: boolean;
  password: string | undefined;
  resetPasswordPage: () => void;
  resetToken: string | undefined;
}) => {
  const translations = useTranslations();
  const locale = useLocale();
  const navigate = useNavigate();
  const toast = useToast();
  const { trackEvent } = useTracking();
  const [loading, setLoading] = useState<QueryProgressActive>(
    QUERY_PROGRESS_NOT_STARTED,
  );

  const changePassword = useCallback(async () => {
    if (!resetToken || !email || !password) return;
    try {
      setLoading(QUERY_PROGRESS_PENDING);
      const loginArgs = await getLoginArguments({ username: email, password });
      await api.auth.changePassword({
        user: email,
        password_hashed: loginArgs.password_hashed,
        salt: loginArgs.salt,
        timestamp: getTimestamp(locale),
        token: resetToken,
      });
      trackEvent({
        name: isActivation
          ? TRACK_EVENTS.ACCOUNT_ACTIVATION_SUCCEEDED
          : TRACK_EVENTS.PASSWORD_CHANGE_SUCCEEDED,
      });
      toast({
        color: "success",
        message: translations.login.passwordResetSuccessfully,
      });
      setTimeout(() => {
        if (careproviderToken) {
          resetPasswordPage();
        } else {
          navigate("/logout?reason=password_reset");
        }
      }, 0);
    } catch (err) {
      console.error(err);
      trackEvent({
        name: isActivation
          ? TRACK_EVENTS.ACCOUNT_ACTIVATION_FAILED
          : TRACK_EVENTS.PASSWORD_CHANGE_FAILED,
      });
    } finally {
      setLoading(QUERY_PROGRESS_NOT_STARTED);
    }
  }, [
    email,
    password,
    resetToken,
    locale,
    careproviderToken,
    setLoading,
    resetPasswordPage,
    navigate,
    toast,
  ]);

  return [loading, changePassword] as const;
};
