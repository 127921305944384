import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { TRACK_EVENTS } from "core/consts";
import { getTopLevelDomain } from "core/model/utils/urls";
import { TrackEventFn } from "core/types";
import RSButton from "ds_legacy/components/RSButton";
import TextAreaInputField from "ds_legacy/components/TextArea/TextAreaForm";
import TextInputField from "ds_legacy/components/TextInputField";
import { withTrackingEvent } from "ds_legacy/hoc";
import { TEXT_DARK_HINT } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, sizing } from "ds_legacy/materials/metrics";
import { Heading, Link, Title } from "ds_legacy/materials/typography";
import Image from "dsl/atoms/Image";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useModal } from "dsl/hooks";
import { customAlphabet } from "nanoid";
import React from "react";
import {
  SimpleFormRenderProps,
  convertModelDefinition,
  valueDef,
} from "react-forms-state";
import styled from "styled-components";

type ErrorInfo = {
  componentStack: string;
};

function generateUID() {
  // for 500 IDs per hour, ~20 days are needed in order to
  // have a 1% probability of at least one collision.
  // https://zelark.github.io/nano-id-cc/
  const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const nanoid = customAlphabet(alphabet, 8);
  return nanoid();
}

const Page = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
`;

function shouldFilterEvent(content: string): [boolean, string | null] {
  try {
    // 134k total, 700 per day
    if (content.includes("Zugriff verweigert")) return [true, "access-denied"];

    // 26k total, up to 150 per day
    if (
      content.includes(
        "In den Microsoft-Interneterweiterungen ist ein interner Fehler aufgetreten",
      )
    )
      return [true, "microsoft-internet-extensions"];

    // 5k total, up to 40 per day
    if (content.includes("Der Vorgang konnte aufgrund"))
      return [true, "unknown-process-error"];
  } catch (_) {
    return [false, null];
  }
  return [false, null];
}

export function BugReportDialog({
  open,
  setOpen,
  uid,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  uid: string;
}) {
  const toast = useToast();

  const onClose = () => setOpen(false);
  return (
    <SimpleFormRenderProps
      formInputValue={null}
      modelDefinition={convertModelDefinition({
        ...valueDef("name", { fieldRequired: true }),
        ...valueDef("email", { fieldRequired: true }),
        ...valueDef("message", { fieldRequired: true }),
      })}
      onSubmit={({
        email,
        message,
        name,
      }: {
        email: string;
        message: string;
        name: string;
      }) => {
        console.error(`[BUG REPORT][${uid}] ${name} | ${email} | ${message} `);
        onClose();
        toast({
          message:
            "Vielen Dank! Der Bericht wurde erfolgreich gesendet und jemand aus unserem Team wird sich drum kümmern.",
          color: "primary",
        });
      }}
    >
      {({ submit }) => (
        <Dialog open={open} fullWidth onClose={onClose}>
          <DialogTitle>{`Etwas ist schief gelaufen (${uid})`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Unser Team wurde hierüber benachrichtigt. Wenn Sie uns helfen
              möchten, teilen Sie uns bitte unten mit, was geschehen ist.
            </DialogContentText>
            <TextInputField
              elementName="name"
              label="Name"
              placeholder="Jane Bloggs"
              fullWidth
              marginOverride={margin(1, 0)}
            />
            <TextInputField
              elementName="email"
              label="E-mail"
              placeholder="erika@beispiel.com"
              fullWidth
              marginOverride={margin(1, 0)}
            />
            <TextAreaInputField
              elementName="message"
              label="Was ist passiert?"
              placeholder={`Ich habe auf das "X" und anschließend auf "Bestätigen" geklickt.`}
              fullWidth
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <RSButton
              color="primary"
              id="close"
              loading="na"
              onClick={onClose}
              variant="text"
            >
              Schließen
            </RSButton>
            <RSButton
              color="primary"
              id="submit"
              inverted
              loading="na"
              onClick={submit}
              variant="text"
            >
              Absturzbericht senden
            </RSButton>
          </DialogActions>
        </Dialog>
      )}
    </SimpleFormRenderProps>
  );
}

export const ErrorPage = ({
  contactRecare,
  errorCode,
  sendBugReport,
  title,
  tryAgain,
  uid,
  web,
}: {
  contactRecare: string;
  errorCode: string;
  sendBugReport: string;
  title: string;
  tryAgain: string;
  uid: string;
  web: string;
}) => {
  const { isMobile } = useMedia();
  const [open, setOpen] = useModal();

  return (
    <Page>
      <Image
        path="illustration-error-page.svg"
        style={{
          height: dp(157),
          width: dp(295),
          marginBottom: isMobile ? sizing(1) : sizing(10),
        }}
        alt=""
      />
      <VerticalLayout aligned>
        <Heading>{title}</Heading>
        <Title disabled>
          {errorCode}
          {uid}
        </Title>
      </VerticalLayout>
      <VerticalLayout aligned margin={margin(1, 0)}>
        <RSButton
          color="primary"
          id="try_again"
          loading="na"
          onClick={() => {
            window.localStorage.clear();
            setTimeout(() => window.location.reload(), 0);
          }}
          style={{ width: dp(343), margin: margin(3, 0) }}
          variant="contained"
        >
          {tryAgain}
        </RSButton>
        <Link href={web} target="_blank" rel="noopener noreferrer">
          {contactRecare}
        </Link>
      </VerticalLayout>
      <Link
        onClick={() => setOpen(true)}
        target="_blank"
        rel="noopener noreferrer"
        color={TEXT_DARK_HINT}
        style={{
          position: "absolute",
          bottom: isMobile ? sizing(2) : sizing(5),
        }}
      >
        {sendBugReport}
      </Link>
      <BugReportDialog open={open} setOpen={setOpen} uid={uid} />
    </Page>
  );
};

function logError({
  componentStack,
  err,
  trackEvent,
  uid,
}: {
  componentStack: string;
  err: Error;
  trackEvent: TrackEventFn;
  uid: string;
}) {
  try {
    const errorTitle = `[Error boundary][${uid}]:`;

    const errorContent = `${err.name} ${err.message} ${componentStack}`;

    const [isEventFiltered, error] = shouldFilterEvent(errorContent);

    console.error(`${errorTitle} ${errorContent}`);

    try {
      let componentErrorSource = componentStack;
      componentErrorSource = componentErrorSource || "componentErrorSource";
      componentErrorSource = componentErrorSource.substring(
        componentErrorSource.indexOf("in") + 3,
      );
      componentErrorSource = componentErrorSource.substring(
        0,
        componentErrorSource.indexOf(" "),
      );
      if (
        typeof componentErrorSource === "string" &&
        componentErrorSource.length > 0
      ) {
        trackEvent({
          name: TRACK_EVENTS.COMPONENT_ERROR_HEATMAP,
          component: componentErrorSource,
          path: window.location.pathname,
        });
      }
      // eslint-disable-next-line no-empty
    } catch (c_err) {}

    if (isEventFiltered) {
      trackEvent({
        name: TRACK_EVENTS.ERROR_BOUNDARY_FILTERED_EVENT,
        error,
      });
    } else {
      console.error(`${errorTitle} ${errorContent}`);
    }
  } catch (e) {
    console.error("[Error boundary exception]:", e, JSON.stringify(err));
  }
}

export function getTranslations() {
  const country = getTopLevelDomain();
  switch (country) {
    case "FR":
      return {
        title: "Une erreur s'est produite",
        errorCode: "Code d'erreur : ",
        tryAgain: "Essayer à nouveau",
        contactRecare: "Contacter Recare",
        web: "https://recaresolutions.fr/nous-contacter/",
        sendBugReport: "Envoyer un rapport d'erreur",
      };
    default:
      return {
        title: "Etwas ist schief gelaufen",
        errorCode: "Fehlercode:",
        tryAgain: "Erneut versuchen",
        contactRecare: "Kontaktieren Sie Recare",
        web: "https://recaresolutions.com/kontakt/",
        sendBugReport: "Fehlerbericht senden",
      };
  }
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode; trackEvent: TrackEventFn },
  {
    error: Error | null | undefined;
    info: ErrorInfo | null | undefined;
    uid: string;
  }
> {
  state = {
    error: null,
    info: null,
    uid: "",
  };
  componentDidCatch(error: Error, info: ErrorInfo): void {
    const uid = generateUID();
    logError({
      err: error,
      componentStack: info ? info.componentStack : "",
      uid,
      trackEvent: this.props.trackEvent,
    });
    this.setState({ error, info, uid });
  }

  render() {
    if (this.state.error) {
      const { contactRecare, errorCode, sendBugReport, title, tryAgain, web } =
        getTranslations();

      return (
        <ErrorPage
          title={title}
          errorCode={errorCode}
          tryAgain={tryAgain}
          contactRecare={contactRecare}
          web={web}
          sendBugReport={sendBugReport}
          uid={this.state.uid}
        />
      );
    }
    return this.props.children;
  }
}

export default withTrackingEvent(ErrorBoundary);
