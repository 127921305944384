import {
  extendVariants,
  LinkProps as NextUILinkProps,
  useLink,
} from "@nextui-org/react";
import { PressEvent } from "@react-types/shared";
import clsx from "clsx";
import { generateTrackName } from "core/validationSchemas/utils";
import {
  cloneElement,
  ComponentProps,
  forwardRef,
  isValidElement,
  ReactNode,
} from "react";
import { useTracking } from "react-tracking";
import { BUTTON_DISPLAY_NAME } from "../button/button";

interface BaseLinkProps
  extends Omit<NextUILinkProps, "showAnchorIcon" | "anchorIcon"> {
  /**
   * The icon to display after the text.
   */
  endIcon?: React.ReactNode;
  /**
   * ID to uniquely identify the link for tracking purposes.
   */
  id: string;
  /**
   * The icon to display before the text.
   */
  startIcon?: React.ReactNode;
}

const BaseLink = forwardRef<HTMLAnchorElement, BaseLinkProps>(
  ({ color, endIcon, id, startIcon, ...props }, ref) => {
    const getIconClone = (icon: ReactNode) =>
      isValidElement(icon)
        ? cloneElement(icon, {
            // @ts-ignore
            "aria-hidden": true,
            height: "1em",
            width: "1em",
            stroke: "currentColor",
            className: clsx({
              "mx-1 flex self-center leading-none text-current": true, // base classes
              "group-hover:text-primary-dark": color === "primary",
              "group-hover:text-secondary-dark": color === "secondary",
              "group-hover:text-success-dark": color === "success",
              "group-hover:text-warning-dark": color === "warning",
              "group-hover:text-danger-dark": color === "danger",
              "group-visited:text-accent-dark": props.isExternal,
            }),
          })
        : null;

    const displayName = (props.as as { displayName?: string })?.displayName;
    const isButton =
      !!displayName && [BUTTON_DISPLAY_NAME, "Button"].includes(displayName);

    const handlePress = (e: PressEvent) => {
      trackEvent({
        name: generateTrackName({ id: id, type: isButton ? "Button" : "Link" }),
      });
      (props.onPress as (e: PressEvent) => void)?.(e);
    };

    const startContent = getIconClone(startIcon);
    const endContent = getIconClone(endIcon);
    const { trackEvent } = useTracking();

    const { children, Component, getLinkProps } = useLink({
      ...props,
      color,
      className: clsx({
        "group !outline-none-v3 w-fit rounded font-bold data-[focus-visible]:outline-focus-visible hover:underline hover:decoration-1 hover:opacity-100":
          true, // base classes
        "!h-auto !min-h-0 !bg-transparent !p-0 hover:!bg-transparent data-[pressed=true]:!translate-y-0 data-[pressed=true]:!transform-none data-[hover=true]:!bg-transparent hover:[&.text-danger]:text-danger hover:[&.text-primary]:text-primary hover:[&.text-secondary]:text-secondary hover:[&.text-success]:text-success hover:[&.text-warning]:text-warning":
          isButton, // overrides default button styles in the case of <Link as={Button} />
        "text-sm": props.size === "sm",
        "text-base": props.size === "md",
        "text-lg": props.size === "lg",
        "inline-flex items-center gap-0": !!startContent || !!endContent,
        "visited:text-accent-dark": props.isExternal,
        [props.className ?? ""]: true, // overwrite classes
      }),
      ref,
      onPress: handlePress,
      role: isButton ? "button" : "link",
      ...(isButton && {
        disableRipple: true,
        disableAnimation: true,
      }),
    });

    return (
      <Component {...getLinkProps()}>
        <>
          {startContent}
          {children}
          {endContent}
        </>
      </Component>
    );
  },
);

BaseLink.displayName = "RecareUI.BaseLink";

const ExtendedLink = extendVariants(BaseLink, {
  variants: {
    color: {
      primary: "text-primary",
      "primary-dark": "text-primary-dark",
      secondary: "text-secondary",
      success: "text-success",
      warning: "text-warning",
      danger: "text-danger",
    },
  },
});

export type LinkProps = Omit<ComponentProps<typeof ExtendedLink>, "id"> & {
  id: string;
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  return <ExtendedLink {...props} ref={ref} data-testid={props.id} />;
});

Link.displayName = "RecareUI.Link";
