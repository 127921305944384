type EncryptionDefinition =
  | boolean
  | {
      [querySignature: string]: EncryptionDefinition;
    };
type Entities = "Auction" | "AuctionRequest" | "Event" | "Patient";

const SINGULAR_ENCRYPTED_PROPERTY_NAME_PATIENT = "patient" as const;
const SINGULAR_ENCRYPTED_PROPERTY_NAME_AUCTION = "auction" as const;
const SINGULAR_ENCRYPTED_PROPERTY_NAME_AUCTION_REQUEST =
  "auctionRequest" as const;
const SINGULAR_ENCRYPTED_PROPERTY_NAME_EVENT = "event" as const;

const SINGULAR_ENCRYPTED_PROPERTY_NAMES = [
  SINGULAR_ENCRYPTED_PROPERTY_NAME_PATIENT,
  SINGULAR_ENCRYPTED_PROPERTY_NAME_AUCTION,
  SINGULAR_ENCRYPTED_PROPERTY_NAME_AUCTION_REQUEST,
  SINGULAR_ENCRYPTED_PROPERTY_NAME_EVENT,
] as const;

type Singular = (typeof SINGULAR_ENCRYPTED_PROPERTY_NAMES)[number];

const PLURAL_ENCRYPTED_PROPERTY_NAME_PATIENT = "patients" as const;
const PLURAL_ENCRYPTED_PROPERTY_NAME_AUCTION = "auctions" as const;
const PLURAL_ENCRYPTED_PROPERTY_NAME_AUCTION_REQUEST = "requests" as const;
const PLURAL_ENCRYPTED_PROPERTY_NAME_EVENT = "events" as const;

const PLURAL_ENCRYPTED_PROPERTY_NAMES = [
  PLURAL_ENCRYPTED_PROPERTY_NAME_PATIENT,
  PLURAL_ENCRYPTED_PROPERTY_NAME_AUCTION,
  PLURAL_ENCRYPTED_PROPERTY_NAME_AUCTION_REQUEST,
  PLURAL_ENCRYPTED_PROPERTY_NAME_EVENT,
] as const;

type Plural = (typeof PLURAL_ENCRYPTED_PROPERTY_NAMES)[number];

/**
 * 1) CREATE ENTITIES
 */

const AuctionFields = {
  transitional_care: {
    form_data: true,
  },
  session_key_context: true,
  medical_supplies: {
    delivery_address: {
      encrypted_house_number: true,
    },
  },
  profile: {
    search_location: {
      encrypted_house_number: true,
    },
    search_destination: {
      encrypted_house_number: true,
    },
  },
  rehab_forms: {
    medical_form: { form_data: true },
    general_form: { form_data: true },
  },
};

export const Patient: EncryptionDefinition = {
  session_key_context: true,
  profile: {
    first_name: true,
    last_name: true,
    birth_date: true,
    communication: {
      guardian_contact_information: true,
      relatives_description: true,
      patient_is_contact_description: true,
    },
    weight: true,
    height: true,
    financing: {
      insurance_number: true,
    },
  },
  auction: AuctionFields,
  auctions: AuctionFields,
  transport: {
    accompanying_person: true,
  },
};

const Auction: EncryptionDefinition = {
  ...AuctionFields,
  patient: Patient,
};

const AuctionRequest: EncryptionDefinition = {
  session_key_context: true,
  auction: Auction,
};

const Event: EncryptionDefinition = {
  auction: Auction,
};

/**
 * 2) LIST ENTITIES
 */
// TODO: unit test single session_key_context per entity
const Entities: { [key in Entities]: EncryptionDefinition } = {
  Patient,
  AuctionRequest,
  Event,
  Auction,
};

// note that encrypted entities have to use these property names otherwise the patcher wont find them
// for instance, if we had auction.auction_request and wanted to encrypt/decrypt the auction_request as an Entity AuctionRequest,
// we would have to change the "Singular" value for the entity AuctionRequest from auctionRequest to auction_request for all cases
// including those in the in the apollo signiture:
// query getAuctionRequest($id: Int!, $ref: String) {
// here ==> auctionRequest(id: $id, ref: $ref)

export const Typenames: { [key in Entities]: [Singular, Plural] } = {
  Patient: [
    SINGULAR_ENCRYPTED_PROPERTY_NAME_PATIENT,
    PLURAL_ENCRYPTED_PROPERTY_NAME_PATIENT,
  ],
  AuctionRequest: [
    SINGULAR_ENCRYPTED_PROPERTY_NAME_AUCTION_REQUEST,
    PLURAL_ENCRYPTED_PROPERTY_NAME_AUCTION_REQUEST,
  ],
  Event: [
    SINGULAR_ENCRYPTED_PROPERTY_NAME_EVENT,
    PLURAL_ENCRYPTED_PROPERTY_NAME_EVENT,
  ],
  Auction: [
    SINGULAR_ENCRYPTED_PROPERTY_NAME_AUCTION,
    PLURAL_ENCRYPTED_PROPERTY_NAME_AUCTION,
  ],
};

/**
 * 3) CREATE MODEL DEFINITION
 */
// for an apollo signiture that looks like this:
// ...
// mutation updateEntity($id: Int! $input: Any!) {
//   entity(id: $id, input: $input)
// ...
//
// the pattern for the model definition should follow:
//   updateEntity: {
//      entity: EncryptionDefinition
//   }
//
// if the encrypted value is nested in the fragment, you should reflect that nesting in the model definition
//
//  i.e. if your input looks like this:
//    operationName: "updateEntity",
//    variables: { input: { context: { entity: TO_ENCRYPT } } }
//
// the pattern for the encryptPatcherModelDefinition should follow:
//  updateEntity: {
//     entity: {
//      context: {
//        entity: EncryptionDefinition
//      }
//     }
//  }
//
//  if your response is in a different structure, you can reflect that structure in the decryptPatcherModelDefinition:

// for operations that the encryption layer should encrypt add the operation here
export const encryptPatcherModelDefinition = {
  updatePatient: {
    patient: Entities.Patient,
  },
  createAuction: {
    auction: Entities.Auction,
  },
  useSearchAction: {
    auction: {
      context: {
        auction: Entities.Auction,
      },
    },
  },
};

// for operations that the decryption layer should decrypt add the operation here
export const decryptPatcherModelDefinition = {
  updatePatient: encryptPatcherModelDefinition.updatePatient,
  createAuction: encryptPatcherModelDefinition.createAuction,
  useSearchAction: {
    auction: Entities.Auction,
  },
  getPatientsNew: {
    patientsNew: {
      patients: Entities.Patient,
    },
  },
  getPatient: {
    patient: Entities.Patient,
  },
  getAuctionRequest: {
    auctionRequest: Entities.AuctionRequest,
  },
  getAuction: {
    auction: Entities.Auction,
  },
  createAdditionalAuction: {
    auction: Entities.Auction,
  },
  getCareproviderRequestsNew: {
    careproviderRequestsNew: { requests: Entities.AuctionRequest },
  },
  getCareproviderNotifications: {
    careproviderNotifications: { events: Entities.Event },
  },
  getTransitionalCare: {
    auction: Entities.Auction,
  },
  getRehabForms: {
    auction: Entities.Auction,
  },
};
