import RSButton from "ds_legacy/components/RSButton";
import { BORDER_COLOR, INFO_CARD_BACKGROUND } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { border, margin } from "ds_legacy/materials/metrics";
import { Title } from "ds_legacy/materials/typography";
import { SearchIcon } from "lucide-react";
import styled from "styled-components";

export function PageWrapper({
  children,
  page,
}: {
  children: React.ReactNode;
  page: string;
}) {
  return (
    <VerticalLayout aligned margin={margin(2)}>
      <Title>Debug {page} encryption status</Title>
      {children}
    </VerticalLayout>
  );
}

export const DetailsTable = styled.table`
  border: ${border({ color: BORDER_COLOR })};
  border-collapse: collapse;
  margin: ${margin(4, 0)};
`;

export const DetailsTableRow = styled.tr`
  border: ${border({ color: BORDER_COLOR })};
  cursor: ${(props) => (props.onClick ? "pointer" : "inherit")};
  &:hover {
    background: ${(props) =>
      props.onClick ? INFO_CARD_BACKGROUND : "inherit"};
  }
`;

export function SearchButton({ submit }: { submit: () => void }) {
  return (
    <RSButton
      color="primary"
      RightIcon={SearchIcon}
      id="search"
      inverted
      loading="na"
      onClick={submit}
      variant="text"
    >
      Search
    </RSButton>
  );
}
