import { fromByteArray, toByteArray } from "base64-js";
import { AES_CBC, TRACK_EVENTS } from "core/consts";
import { CryptoWorker, cryptoService } from "core/model/crypto/singleton";
import { differenceInMilliseconds } from "date-fns";
import { ToType, TrackEventFn } from "../../types";

/*
 * For Web Crypto examples see: https://github.com/diafygi/webcrypto-examples
 */

type DataToEncrypt =
  | ArrayBuffer
  | DataView
  | Float32Array
  | Float64Array
  | Int8Array
  | Int16Array
  | Int32Array
  | Uint8Array
  | Uint8ClampedArray
  | Uint16Array
  | Uint32Array;

export function hasCrypto(): boolean {
  return !!cryptoService?.subtle;
}

export function bytesToBase64(bytes: ToType | Uint8Array) {
  const isUint8Array = bytes instanceof Uint8Array;
  return fromByteArray(isUint8Array ? bytes : new Uint8Array(bytes));
}

export function base64ToBytes(string: any) {
  return toByteArray(string);
}

export async function generateHash(content: string, algo = "SHA-1") {
  const contentArrayBuffer = await cryptoService.subtle.digest(
    algo,
    new TextEncoder().encode(content),
  );
  const contentHash = bytesToBase64(contentArrayBuffer);
  return contentHash;
}

export function generateSalt() {
  return cryptoService.getRandomValues(new Uint8Array(16));
}

export async function generateSaltedHash(data: string, salt: string) {
  if (!hasCrypto()) return undefined;

  const salted = data.concat(salt);

  try {
    const buffer = await cryptoService.subtle.digest(
      "SHA-256",
      new TextEncoder().encode(salted),
    );

    // convert bytes to hex string
    const readableBuffer = Array.from(new Uint8Array(buffer))
      .map((b) => ("00" + b.toString(16)).slice(-2))
      .join("");

    return readableBuffer;
  } catch (e) {
    console.error("Could not hash password", e);
    return undefined;
  }
}

export function generateAESKey(algo: string) {
  return cryptoService.subtle.generateKey(
    {
      name: algo,
      length: 256,
    },
    true,
    ["encrypt", "decrypt"],
  );
}

function deriveAESKeyFallback(
  password: BufferSource,
  salt: Uint8Array,
  iterations: number,
): Promise<string | BufferSource> {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const worker = new CryptoWorker();
    worker.onmessage = ({ data }: ToType) => {
      const { payload, type } = data;
      switch (type) {
        case "keyDerived": {
          const { derivedKey } = payload;
          resolve(derivedKey);
          return;
        }
        default:
          reject(new Error(`unhandled worker message type: ${type}`));
      }
      worker.terminate();
    };
    worker.postMessage({
      type: "deriveKey",
      payload: {
        password,
        salt,
        iterations,
        len: 16,
      },
    });
  });
}

export function importAESKey(
  key: BufferSource | string,
  algorithm: string | null,
) {
  return cryptoService.subtle.importKey(
    "raw",
    typeof key === "string" ? toByteArray(key) : key,
    { name: algorithm || AES_CBC },
    true,
    ["encrypt", "decrypt"],
  );
}

async function deriveAESKeyOperation(
  password: BufferSource,
  salt: Uint8Array,
  iterations: number,
) {
  let key;

  try {
    const cryptoKey = await cryptoService.subtle.importKey(
      "raw",
      password,
      { name: "PBKDF2" },
      false,
      ["deriveKey"],
    );

    key = cryptoService.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt,
        iterations,
        hash: "SHA-256",
      },
      cryptoKey,
      {
        name: "AES-CBC",
        length: 128,
      },
      true,
      ["encrypt", "decrypt"],
    );
  } catch (e) {
    key = importAESKey(
      await deriveAESKeyFallback(password, salt, iterations),
      null,
    );
  }

  return key;
}

export async function deriveAESKey({
  iterations,
  password,
  salt,
  trackEvent,
}: {
  iterations: number;
  password: DataToEncrypt;
  salt: Uint8Array;
  trackEvent: TrackEventFn;
}) {
  const startTime = new Date();
  const key = await deriveAESKeyOperation(password, salt, iterations);
  const duration = differenceInMilliseconds(new Date(), startTime);

  trackEvent({
    name: TRACK_EVENTS.DERIVE_AES_KEY,
    duration,
    iterations,
  });
  return key;
}

export async function generateRSAKeysJWK() {
  const { privateKey, publicKey } = await cryptoService.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 2048,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: { name: "SHA-256" },
    },
    true,
    ["encrypt", "decrypt"],
  );

  return {
    privateKey: await cryptoService.subtle.exportKey(
      "jwk",
      privateKey as CryptoKey,
    ),
    publicKey: await cryptoService.subtle.exportKey(
      "jwk",
      publicKey as CryptoKey,
    ),
  };
}

export function importPublicKey(key: CryptoKey | string) {
  if (typeof key !== "string")
    throw new Error(`key must be a string: ${JSON.stringify(key)}`);
  return cryptoService.subtle.importKey(
    "jwk",
    JSON.parse(key),
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" },
    },
    false,
    ["encrypt"],
  );
}

export async function importPrivateKey(key: CryptoKey | string | undefined) {
  if (typeof key !== "string") {
    console.error("key must be a string", JSON.stringify(key));
    return null;
  }

  try {
    return await cryptoService.subtle.importKey(
      "jwk",
      JSON.parse(key),
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" },
      },
      false,
      ["decrypt"],
    );
  } catch (e) {
    console.error("Could not import private key", key, e);
    return null;
  }
}

export function RSADecrypt(data: DataToEncrypt, key: CryptoKey) {
  return cryptoService.subtle.decrypt(
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" },
    } as RsaOaepParams,
    key,
    data,
  );
}

export function RSAEncrypt(data: BufferSource, key: CryptoKey) {
  return cryptoService.subtle.encrypt(
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" },
    } as RsaOaepParams,
    key,
    data,
  );
}

export function AESDecrypt(
  data: DataToEncrypt,
  key: CryptoKey,
  iv: Uint8Array,
) {
  return cryptoService.subtle.decrypt(
    {
      name: "AES-CBC",
      iv,
    },
    key,
    data,
  );
}

export function AESEncrypt(
  data: DataToEncrypt,
  key: CryptoKey,
  iv: Uint8Array,
) {
  return cryptoService.subtle.encrypt(
    {
      name: "AES-CBC",
      iv,
    },
    key,
    data,
  );
}

export function exportKey(key: CryptoKey) {
  return cryptoService.subtle.exportKey("raw", key);
}
