import SvgIcon from "@mui/material/SvgIcon";
import { disabledFlagStyle } from "./index";

export default function DeFlag({ active }: { active?: boolean }) {
  return (
    <SvgIcon
      titleAccess="flag-de"
      viewBox="0 0 640 480"
      style={!active ? disabledFlagStyle : {}}
      aria-hidden
      role="presentation"
    >
      <g fillRule="evenodd" strokeWidth="1pt">
        <path fill="#ffe600" d="M0 320h640v160.002H0z" />
        <path fill="black" d="M0 0h640v160H0z" />
        <path fill="red" d="M0 160h640v160H0z" />
      </g>
    </SvgIcon>
  );
}
