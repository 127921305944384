import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import withStyles from "@mui/styles/withStyles";
import { margin } from "ds_legacy/materials/metrics";
import {
  MAX,
  MEDIUM,
  MIN,
  STRONG,
  VERY_STRONG,
  VERY_WEAK,
  WEAK,
} from "dsl/hooks/usePasswordStrength";
import { useMemo } from "react";
import styled from "styled-components";
import Translations from "translations/types";

const Container = styled.div<{ hide: boolean | undefined }>`
  display: flex;
  align-items: center;
  margin: ${margin(0, 2)};
  opacity: ${({ hide }) => (hide ? "0" : "1")};
`;

type StrengthIndicatorProps = {
  hide?: boolean;
  strength: number;
  translations: Translations;
};

type StrengthConfig = {
  color: string;
  text: string;
};

const normalise = (value: number) => {
  const realValue = value + 1;
  const min = MIN;
  const max = MAX + 1;

  return ((realValue - min) * 100) / (max - min);
};

const BarComponent = withStyles({
  root: { flex: 1 },
})(LinearProgress);

const BarContainer = styled.span<{ barColor: string }>`
  flex: 1;
  & .MuiLinearProgress-bar1Determinate {
    background-color: ${({ barColor }) => barColor};
  }
`;

function StrengthBar({
  barColor,
  ...props
}: LinearProgressProps & { barColor: string }) {
  return (
    <BarContainer barColor={barColor}>
      <BarComponent {...props} />
    </BarContainer>
  );
}

function StrengthIndicator({
  hide,
  strength,
  translations,
}: StrengthIndicatorProps) {
  const { color }: StrengthConfig = useMemo(() => {
    switch (strength) {
      case VERY_STRONG:
        return { text: "Very strong", color: "darkgreen" };
      case STRONG:
        return { text: "Strong", color: "mediumseagreen" };
      case MEDIUM:
        return { text: "Medium", color: "darkkhaki" };
      case WEAK:
        return { text: "Weak", color: "tomato" };
      case VERY_WEAK:
      default:
        return { text: "Very weak", color: "crimson" };
    }
  }, [strength, translations]);

  return (
    <Container hide={hide}>
      <StrengthBar
        variant="determinate"
        value={normalise(strength)}
        barColor={color}
      />
    </Container>
  );
}

export default StrengthIndicator;
