import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction, {
  SpeedDialActionProps,
} from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { useTheme } from "@mui/styles";
import { getKey } from "core/model/utils/strings";
import { newThemeColors } from "ds_legacy/materials/colors";
import { dp, sizing } from "ds_legacy/materials/metrics";
import { useState } from "react";

export type SpeedDialAction = Pick<
  SpeedDialActionProps,
  "icon" | "tooltipTitle"
> & {
  onClick: () => void;
  testId?: string;
};

type SpeedDialProps = {
  actions: Array<SpeedDialAction>;
  initialState?: boolean;
  testId?: string;
  tooltip: string;
};

export default function SpeedDialInput({
  actions,
  initialState = false,
  testId,
  tooltip,
}: SpeedDialProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(initialState);
  const onClick = () => setOpen(!open);

  return (
    <div style={{ height: open ? undefined : sizing(6) }}>
      <SpeedDial
        FabProps={{
          sx: {
            color: newThemeColors.gray[900],
            margin: "8px",
            backgroundColor: theme.palette.secondary.dark,
            width: dp(40),
            height: dp(40),
            "&:hover": {
              backgroundColor: theme.palette.secondary.dark,
            },
          },
          ...{ "data-testid": testId },
        }}
        ariaLabel={tooltip}
        icon={<SpeedDialIcon />}
        onClick={onClick}
        open={open}
        direction="down"
      >
        {actions.map(({ icon, onClick, testId, tooltipTitle }, index) => (
          <SpeedDialAction
            FabProps={{ sx: {}, ...{ "data-testid": testId } }}
            icon={icon}
            key={getKey(index)}
            onClick={() => {
              onClick();
              setOpen(false);
            }}
            sx={{
              "& .MuiSpeedDialAction-staticTooltipLabel": { width: sizing(26) },
            }}
            tooltipOpen
            tooltipTitle={tooltipTitle}
          />
        ))}
      </SpeedDial>
    </div>
  );
}
