import {
  RESPONSIVE_BREAKPOINT_MOBILE,
  RESPONSIVE_BREAKPOINT_TABLET,
} from "core/consts";
import InfoCaption from "ds_legacy/components/InfoCaption";

import { APP_BACKGROUND, ICON_GREY } from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, sizing } from "ds_legacy/materials/metrics";
import {
  Caption,
  Display,
  FONT_SIZE_15,
  FONT_SIZE_20,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import React from "react";
import styled from "styled-components";

export const iconStyle = {
  fontSize: FONT_SIZE_20,
  color: ICON_GREY,
  marginTop: sizing(2),
};
export const OnboardingWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: ${APP_BACKGROUND};
  position: relative;
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_TABLET)}) {
    & {
      flex-direction: column;
    }
  }
`;

export const OnboardingPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_TABLET)}) {
    & {
      top: ${sizing(9)};
    }
  }
`;

export const PageTitle = ({
  isTablet,
  title,
}: {
  isTablet: boolean;
  title: string;
}) => {
  if (isTablet)
    return (
      <Title data-testid="page-title" primary dark margin={margin(0)}>
        {title}
      </Title>
    );

  return (
    <Display
      data-testid="page-title"
      primary
      dark
      style={{
        display: "flex",
        justifyContent: "center",
        margin: margin(6, 2),
        whiteSpace: "nowrap",
      }}
    >
      {title}
    </Display>
  );
};

const Header = styled(Subheading)`
  margin: ${margin(2, 0)};
  font-size: ${FONT_SIZE_20};
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_MOBILE)}) {
    & {
      font-size: ${FONT_SIZE_15};
      margin: ${margin(1, 0, 1, 0)};
    }
  }
`;

export const CardTitle = ({
  currentStep,
  title,
}: {
  currentStep?: string;
  title: string;
}) => {
  const { isTablet } = useMedia();
  if (isTablet)
    return (
      <HorizontalLayout>
        <Header data-testid="onboarding-title" bold>
          {title}
        </Header>
        {currentStep && (
          <Caption margin={margin(2, 0, 0, 1)}>{currentStep}</Caption>
        )}
      </HorizontalLayout>
    );

  return (
    <Header data-testid="onboarding-title" bold>
      {title}
    </Header>
  );
};

export const PageWrapperWithInfo = styled.div`
  display: flex;
  justify-content: center;
  margin: ${margin(2, 0)};
  margin-left: ${sizing(47)};
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_TABLET)}) {
    & {
      margin: ${margin(0)};
    }
  }
`;

export const ButtonWrapper = styled.div<{ singleButton?: boolean }>`
  display: flex;
  justify-content: ${({ singleButton }) =>
    singleButton ? "flex-end" : "space-between"};
  width: ${sizing(52)};
  margin: ${margin(3, 0)};
  box-sizing: border-box;
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_TABLET)}) {
    & {
      width: 90vw;
      align-self: center;
    }
  }
`;

export const SingleButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: margin(2, 0);
`;

export const ButtonTextWrapper = styled.span`
  width: 100%;
`;

export const HintWrapper = styled.div`
  width: ${sizing(47)};
`;

export function HintContainer({ children }: { children: React.ReactNode }) {
  const { isTablet } = useMedia();
  return (
    <HorizontalLayout
      style={{
        margin: isTablet ? margin(0, 0.5) : margin(10, 3),
        maxWidth: sizing(42),
      }}
    >
      <InfoCaption>{children}</InfoCaption>
    </HorizontalLayout>
  );
}
