import { BarthelIndex, Country, RadioOption } from "core/types";
import memoizeOne from "memoize-one";
import Translations from "translations/types";
import {
  OntologyStructValue,
  OntologyType,
  deprecatedOntologies,
  ontologies,
  ontologiesOrders,
} from ".";
import { barthelElements } from "../../../consts";

type Ontology = { name: string; value: number };

export function getName(type: OntologyType, translations: Translations) {
  const ontologyStruct = translations.ontologies[type];

  if (!ontologyStruct) {
    console.error(`Could not find ontology translations for ${type}`);
    return "";
  }

  if (!ontologyStruct.key) {
    console.error(`Could not find key value for ontology type: ${type}`);
    return type;
  }

  return ontologyStruct?.key;
}

export const getValues = memoizeOne(
  (type: OntologyType): Array<number | string> => {
    if (!(type in ontologies)) {
      console.error(`Could not find type ${type} in ontologies`);
      return [];
    }

    let keys = Object.keys(ontologies[type]);

    keys = keys.filter((key: string) => {
      const newKey = parseInt(key);
      return !isNaN(newKey);
    });

    const order = ontologiesOrders[type];
    if (order)
      return keys.sort(
        (a: string, b: string) => order[parseInt(a)] - order[parseInt(b)],
      );

    return keys;
  },
);

export function getKey(
  type: OntologyType,
  key: number | null | undefined,
): OntologyStructValue | "" {
  if (key == null) return "";

  const ontologyStruct = ontologies[type];
  if (!ontologyStruct) {
    console.error(`Could not find type ${type} in ontologies`);
    return "";
  }

  const deprecatedOntologyStruct = deprecatedOntologies[type];

  const idToKey = ontologyStruct[
    key as keyof typeof ontologyStruct
  ] as OntologyStructValue;

  const deprecatedIdToKey = deprecatedOntologyStruct?.[key];

  const returnKey: OntologyStructValue | "" =
    idToKey ?? deprecatedIdToKey ?? "";

  return returnKey;
}

export function getOntology(
  type: OntologyType,
  key: number | null | undefined,
  translations: Translations,
  country?: Country,
  useShortValues?: boolean,
): string {
  const ontologyStruct = translations.ontologies[type] as {
    shortValues?: { [index in string]: string };
    values: { [index in string]: string };
  };

  if (!ontologyStruct) {
    console.error(`Could not find ontology translations for ${type}`);
    return "";
  }

  if (key === -1) {
    return translations.general.unknown;
  }

  const idtoKey = getKey(type, key);
  if (idtoKey === "") return idtoKey;

  const value = useShortValues
    ? ontologyStruct?.shortValues?.[idtoKey]
    : ontologyStruct.values[idtoKey];

  if (value == null) {
    console.error(
      `Could not find value for key '${idtoKey}' in ontology ${type}`,
    );
    return "";
  }

  return value;
}

function getOntologyLabel(
  type: OntologyType,
  id: number | string,
  translations: Translations,
) {
  const ontology = getOntology(type, parseInt(id.toString()), translations);
  if (ontology) {
    return ontology;
  } else if (barthelElements.includes(type as keyof BarthelIndex["form"])) {
    return id.toString();
  }
  return "";
}

function removeUmlauts(value: string) {
  return value.replace(/Ä/g, "A").replace(/Ö/g, "O").replace(/Ü/g, "U");
}

export function getOntologiesForSelect(
  type: OntologyType,
  sort = true,
  translations: Translations,
) {
  // using RadioOption instead of SelectOption, since it's more strictly defined
  let values = getValues(type).reduce<RadioOption[]>((acc, id) => {
    const ontology = {
      label: getOntologyLabel(type, id, translations),
      value: parseInt(id.toString()),
      id: parseInt(id.toString()),
    };
    return id ? [...acc, ontology] : acc;
  }, []);

  const order = ontologiesOrders[type];
  if (!order && sort)
    values = values.sort((a, b) => {
      const labelA = removeUmlauts(a.label.toUpperCase());
      const labelB = removeUmlauts(b.label.toUpperCase());
      return labelA > labelB ? 1 : labelA < labelB ? -1 : 0;
    });

  return values;
}
export function getOntologies(
  type: OntologyType,
  sort = true,
  translations: Translations,
) {
  let values = getValues(type).reduce<Ontology[]>((acc, id) => {
    const ontology = {
      name: getOntologyLabel(type, id, translations),
      value: parseInt(id.toString()),
    };
    return id ? [...acc, ontology] : acc;
  }, []);

  const order = ontologiesOrders[type];
  if (!order && sort)
    values = values.sort((a, b) =>
      a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
    );

  return values;
}

export const validOntology = (
  value: Array<number> | number | null | undefined,
) => {
  if (!value) return false;

  if (typeof value === "number") return value > 0;
  if (Array.isArray(value)) return value && value.length > 0;

  return false;
};

export function getValueFromTranslation(
  type: OntologyType,
  translation: string,
  translations: Translations,
) {
  return getValues(type).find(
    (id) =>
      getOntology(type, parseInt(id as string), translations) == translation,
  );
}
