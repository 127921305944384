import {
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  TOKEN_INVALID_REASONS,
  TRACK_EVENTS,
} from "core/consts";
import api from "core/model/api";
import { QueryProgressActive, TokenInvalidReason } from "core/types";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useEffect, useState } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";

export const useVerifyToken = ({
  isActivation,
  token,
}: {
  isActivation: boolean;
  token: string | undefined;
}) => {
  const toast = useToast();
  const { trackEvent } = useTracking();
  const translations = useTranslations();
  const [loading, setLoading] = useState<QueryProgressActive>(
    QUERY_PROGRESS_PENDING, // Set to pending by default to ensure loading screen renders first
  );
  const [email, setEmail] = useState<string>();
  const [verifiedToken, setVerifiedToken] = useState<string>();
  const [tokenInvalidReason, setTokenInvalidReason] =
    useState<TokenInvalidReason>();

  useEffect(() => {
    if (!token || token === verifiedToken) {
      setLoading(QUERY_PROGRESS_NOT_STARTED);
      return;
    }

    api.auth
      .verifyToken(token)
      .then((data) => {
        if (data.valid) {
          setEmail(data.email);
          setTokenInvalidReason(undefined);
          setVerifiedToken(token);
          trackEvent({
            name: isActivation
              ? TRACK_EVENTS.ACCOUNT_ACTIVATION_TOKEN_VALID
              : TRACK_EVENTS.PASSWORD_CHANGE_TOKEN_VALID,
          });
        } else {
          const reason = data.error.reason ?? TOKEN_INVALID_REASONS.INVALID;
          setEmail(undefined);
          setTokenInvalidReason(reason);
          setVerifiedToken(undefined);
          trackEvent({
            name: isActivation
              ? TRACK_EVENTS.ACCOUNT_ACTIVATION_TOKEN_INVALID
              : TRACK_EVENTS.PASSWORD_CHANGE_TOKEN_INVALID,
            reason,
          });
        }
      })
      .catch((err) => {
        console.error("[useVerifyToken]", JSON.stringify({ err }));
        toast({
          color: "danger",
          message: translations.general.error,
        });
        const reason = TOKEN_INVALID_REASONS.INVALID;
        setEmail(undefined);
        setTokenInvalidReason(reason);
        setVerifiedToken(undefined);
        trackEvent({
          name: isActivation
            ? TRACK_EVENTS.ACCOUNT_ACTIVATION_FAILED
            : TRACK_EVENTS.PASSWORD_CHANGE_FAILED,
          reason,
        });
      })
      .finally(() => {
        // reset progress after validating the token
        setLoading(QUERY_PROGRESS_NOT_STARTED);
      });
  }, [token, verifiedToken]);

  return [loading, email, tokenInvalidReason] as const;
};
