import { useGetAccountsForCareseeker } from "apollo/hooks/queries";
import {
  PAGINATION_COUNT_20,
  PAGINATION_START_0,
  SORT_ORDER_ASC,
} from "core/consts";
import { formatUnixDate } from "core/model/utils/dates";
import {
  Account,
  Careseeker,
  GetAccountsForCareseekerQueryString,
  SortOrder,
} from "core/types";
import { useState } from "react";
import { Locale } from "translations";
import { useCreateAccountForCareseeker } from "./useCreateAccountForCareseeker";
import { useAccountByEmailHandler } from "./useGetAccountByEmail";
import { useUpdateAccountForCareseeker } from "./useUpdateAccountForCareseeker";

export type AccountDialogState = {
  account?: Account;
  error?: string;
  isOnlyActiveUserManager?: boolean;
  isOtherCareseekerAccount?: boolean;
  prevStep?: Step | null;
  step: Step | null;
};

export type GetAccountsForCareseekerFilters = {
  count: number;
  search_term: string;
  sort_field: string;
  sort_order: SortOrder;
  start: number;
};

export const STEP_1 = 1;
export const STEP_2 = 2;
const STEPS = [STEP_1, STEP_2] as const;
type Step = (typeof STEPS)[number];

function buildQueryString(
  filters: GetAccountsForCareseekerFilters,
): GetAccountsForCareseekerQueryString {
  return `search_term=${encodeURIComponent(filters.search_term)}&start=${
    filters.start
  }&count=${filters.count}&sort_field=${filters.sort_field}&sort_order=${
    filters.sort_order
  }`;
}

export function getTimestamp(locale: Locale) {
  return encodeURIComponent(
    formatUnixDate(new Date().getTime() / 1000, locale, {
      customFormat: "dd.MM.yyyy HH:mm",
    }),
  );
}
export const initialPagination = {
  start: PAGINATION_START_0,
  count: PAGINATION_COUNT_20,
} as const;

export function useUserManagementFeature({
  careseeker,
}: {
  careseeker: Careseeker | null;
}) {
  const [filtersState, setFiltersState] =
    useState<GetAccountsForCareseekerFilters>({
      search_term: "",
      ...initialPagination,
      sort_field: "status",
      sort_order: SORT_ORDER_ASC,
    });
  const filters = buildQueryString(filtersState);

  const [accountDialog, setAccountDialog] = useState<AccountDialogState>({
    account: undefined,
    step: null,
  });

  const [accountsProgress, accountsPayload] = useGetAccountsForCareseeker({
    variables: { careseekerId: careseeker?.id || -1, filters },
    fetchPolicy: "network-only",
  });

  const getAccountProps = useAccountByEmailHandler({
    careseeker,
    setAccountDialog,
  });

  const createAccountProps = useCreateAccountForCareseeker({
    careseeker,
    setAccountDialog,
  });

  const updateAccountProps = useUpdateAccountForCareseeker({
    careseeker,
    setAccountDialog,
  });

  return {
    ...accountsPayload,
    ...createAccountProps,
    ...getAccountProps,
    ...updateAccountProps,
    accountDialog,
    accountsProgress,
    filtersState,
    setAccountDialog,
    setFiltersState,
  };
}
