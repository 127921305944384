import {
  ApolloCache,
  BaseMutationOptions,
  MutationHookOptions,
  MutationUpdaterFunction,
  OperationVariables,
  gql,
  useMutation,
} from "@apollo/client";
import { getAuctionRequestEventQuery } from "apollo/hooks/queries";
import {
  ADD_ACCOUNT_ROLES,
  ADD_CAREPROVIDER_ACCOUNT_ROLES,
  ADD_PRIORITY_PROVIDER,
  ARCHIVE_PATIENT,
  ARCHIVE_PROVIDER_SEARCH_PATIENT,
  CAREPROVIDER_ACCOUNT_ASSOCIATION,
  CHECK_SEALD_SESSIONS,
  COMPLETE_PRODUCT_TOUR,
  CONNEXT_AUTHENTICATION,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_FOR_CARESEEKER,
  CREATE_ADDITIONAL_AUCTION,
  CREATE_AUCTION,
  CREATE_CAREPROVIDER,
  CREATE_CAREPROVIDER_REVERSE_SEARCH,
  CREATE_CARESEEKER,
  CREATE_COMPANY,
  CREATE_CONSULTANT,
  CREATE_EMAIL_TRANSLATION,
  CREATE_ENCRYPTION_CONTEXT,
  CREATE_FILE,
  CREATE_INSURANCE,
  CREATE_NATIVE_TRANSLATION,
  CREATE_NATIVE_TRANSLATIONS,
  CREATE_ONPREMISE_CONFIG,
  CREATE_ONPREMISE_IMPORT,
  CREATE_PATIENT,
  CREATE_PROVIDERSEARCH_CARESEEKER,
  CREATE_PROVIDER_SEARCH_ACCOUNT,
  CREATE_TRANSLATION,
  CREATE_TRANSLATIONS,
  CREATE_USER_MANAGEMENT_FILE,
  DELETE_ACCOUNT_ROLE,
  DELETE_BEDROCK_EXAMPLE,
  DELETE_BEDROCK_PROMPT,
  DELETE_BEDROCK_RESPONSE,
  DELETE_BEDROCK_SCHEMA,
  DELETE_CAREPROVIDER_ACCOUNT_ROLE,
  DELETE_CAREPROVIDER_DPA,
  DELETE_CAREPROVIDER_FILE,
  DELETE_CAREPROVIDER_REVERSE_SEARCH,
  DELETE_CONSULTANT_FILE,
  DELETE_EMAIL_TRANSLATION,
  DELETE_FILE,
  DELETE_NATIVE_TRANSLATION,
  DELETE_PRIORITY_PROVIDER,
  DELETE_SEALD_ACCESS,
  DELETE_TRANSLATION,
  DELETE_USER_MANAGEMENT_FILE,
  FEEDBACK_PATIENT_INFO_MISSING,
  FORGOT_PASSWORD,
  GET_ACCOUNTS,
  GET_ACCOUNT_WITHIN_CARESEEKER_GROUP,
  GET_ADDRESS,
  GET_AUCTIONREQUEST,
  GET_CAREPROVIDER,
  GET_CAREPROVIDER_FILES,
  GET_CAREPROVIDER_REVERSE_SEARCHES,
  GET_CARESEEKER,
  GET_CARESEEKER_ACCOUNTS,
  GET_COMPANY,
  GET_CONSULTANT_FILES,
  GET_DYNAMIC_AUCTION_ASSESSMENT_STEPS,
  GET_DYNAMIC_AUCTION_FORM_ALL_FIELDS,
  GET_DYNAMIC_AUCTION_FORM_CONFIGURATION,
  GET_PATIENT,
  GET_RECEIVER_ACCOUNTS,
  GET_SENDER_RECEIVER_NOTE,
  INITIATE_SEALD_IDENTITY,
  LOGIN_PROVIDER_SEARCH_ACCOUNT,
  NOTIFY_RECEIVER_RESPONSIBLE_PERSON,
  NOTIFY_SOCIAL_WORKER,
  POST_ENCRYPTION_JOB,
  REQUEST_BULK_ACTION,
  SAVE_BEDROCK_EXAMPLE,
  SAVE_BEDROCK_PROMPT,
  SAVE_BEDROCK_RESPONSE,
  SAVE_BEDROCK_SCHEMAS,
  SEALD_GROUP_RESET,
  SEND_FAX_FOR_AUCTION_REQUEST,
  SEND_ONPREMISE_LOGS,
  SEND_TEST_EMAIL,
  START_ELECTIVE_FLOW,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_FOR_CARESEEKER,
  UPDATE_ASSOCIATIONS,
  UPDATE_BROWSER_SUBSCRIPTION,
  UPDATE_CAPACITY,
  UPDATE_CAREPROVIDER,
  UPDATE_CAREPROVIDER_ACCOUNT,
  UPDATE_CAREPROVIDER_ACCOUNT_TOKEN,
  UPDATE_CAREPROVIDER_REVERSE_SEARCH,
  UPDATE_CAREPROVIDER_TOKEN,
  UPDATE_CARESEEKER,
  UPDATE_COMPANY,
  UPDATE_COMPANY_TOKEN,
  UPDATE_CONSULTANT,
  UPDATE_EMAIL_TRANSLATION,
  UPDATE_FILE,
  UPDATE_IK_NUMBERS,
  UPDATE_INSURANCE,
  UPDATE_NATIVE_TRANSLATION,
  UPDATE_ONPREMISE_CONFIG,
  UPDATE_RECEIVER_SESSION_KEYS,
  UPDATE_SEARCH_CANDIDATES,
  UPDATE_SENDER_RECEIVER_NOTE,
  UPDATE_TRANSLATION,
  UPLOAD_CAREPROVIDER_DPA,
  UPLOAD_CAREPROVIDER_FILE,
  UPLOAD_CONSULTANT_FILE,
  USE_REQUEST_ACTION,
  USE_SEARCH_ACTION,
} from "core/apollo/graphql";
import { careproviderDpa } from "core/apollo/graphql/fragments/fragments";
import {
  DOCUMENT_DATA_PROCESSING_AGREEMENT,
  DOCUMENT_TERMS_AND_CONDITIONS,
  FILE_TYPES,
  REDIRECT_PARAM,
  REPOSITORY_VOICE,
  RETURN_PARAM,
} from "core/consts";
import { getCreateAccountErrorMessage } from "core/model/accounts/accountErrorHandling";
import { getAuctionForDynamo } from "core/model/auctions";
import { useApolloEncryptionContext } from "core/model/patients/encryption/utils";
import { getReferrer } from "core/model/utils/urls";
import {
  Account,
  AddressCircle,
  AnyObject,
  AppType,
  Auction,
  AuctionRequest,
  BedrockExample,
  BedrockPrompt,
  BedrockResponse,
  BedrockResponsePayload,
  BedrockSchema,
  BrowserSubscription,
  Candidates,
  Capacity,
  Careprovider,
  CareproviderDpaFile,
  CareproviderFile,
  Careseeker,
  Company,
  Consultant,
  Country,
  CreateUserManagmentFileParams,
  DPAFileType,
  EncryptedField,
  EncryptionContext,
  EncryptionJobRequest,
  FaxResponse,
  FileCategory,
  FileParams,
  FileShareMode,
  Filev2,
  Insurance,
  OauthAuthenticationInput,
  OnPremImport,
  OnpremiseConfig,
  OnpremiseConfigInput,
  Patient,
  PrecomputePatientWhitelist,
  ProductTourCompletion,
  ProviderBlackListing,
  RequestActionType,
  SealdAccessPayload,
  SealdIdentityInput,
  SealdIdentityPayload,
  SealdSessionsPayload,
  SearchActionsWithHandlers,
  SenderReceiverNote,
  SenderUserManagementFile,
  SessionKey,
  UndoActionType,
  ValueOf,
} from "core/types";
import { getUnixTime } from "date-fns";
import { useAuctionRequestEventsContext } from "dsl/atoms/AuctionRequestEventsContext";
import {
  useLegalDocuments,
  useLegalDocumentsContext,
} from "dsl/atoms/LegalDocuments";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { SendEmail } from "dsl/ecosystems/AcpTestEmail";
import {
  EmailRepositoryType,
  EmailTranslation,
  FormValue,
  RepositoryType,
  TranslationType,
  TranslationTypes,
} from "dsl/ecosystems/AcpTranslations/types";
import {
  ReceiverFilters,
  ReverseSearch,
} from "dsl/organisms/Filters/ReceiverApp/types";
import { useCallback, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslations } from "translations";
import {
  useGenericMutation,
  useGenericProgress,
  useRefetchOnConflict,
} from "./utils";

export function useUpdateTranslation(
  repo: RepositoryType,
  options?: MutationHookOptions<
    { translation: TranslationType },
    { id: string; input: TranslationType }
  >,
) {
  const [mutation, { data, error, loading }] = useMutation<
    { translation: TranslationType },
    { id: string; input: TranslationType }
  >(
    repo === REPOSITORY_VOICE ? UPDATE_NATIVE_TRANSLATION : UPDATE_TRANSLATION,
    options,
  );
  const [updateTranslation, progress, reset] = useGenericProgress<
    { translation: TranslationType },
    { id: string; input: TranslationType }
  >(mutation, { loading, error });

  const translation = data?.translation;
  return [updateTranslation, progress, reset, translation, error] as const;
}

export function useCreateTranslation(
  repo: RepositoryType,
  options?: MutationHookOptions<
    { translation: TranslationType },
    { input?: Partial<TranslationType> }
  >,
) {
  const [mutation, { data, error, loading }] = useMutation<
    { translation: TranslationType },
    { input?: Partial<TranslationType> }
  >(
    repo === REPOSITORY_VOICE ? CREATE_NATIVE_TRANSLATION : CREATE_TRANSLATION,
    options,
  );
  const [updateTranslation, mutationProgress, resetProgress] =
    useGenericProgress<
      { translation: TranslationType },
      { input?: Partial<TranslationType> }
    >(mutation, {
      loading,
      error,
    });

  const mutate = useCallback(
    (input?: Partial<TranslationType> | undefined) =>
      updateTranslation({ variables: { input } }),
    [updateTranslation],
  );

  const translation = data?.translation;
  return [mutate, mutationProgress, resetProgress, translation, error] as const;
}

export function useDeleteTranslation(
  repo: RepositoryType,
  options?: MutationHookOptions<
    { translation: TranslationType },
    { id: string }
  >,
) {
  const [mutation, { data, error, loading }] = useMutation<
    { translation: TranslationType },
    { id: string }
  >(
    repo === REPOSITORY_VOICE ? DELETE_NATIVE_TRANSLATION : DELETE_TRANSLATION,
    options,
  );
  const [updateTranslation, progress, reset] = useGenericProgress<
    { translation: TranslationType },
    { id: string }
  >(mutation, { loading, error });

  const translation = data?.translation;
  return [updateTranslation, progress, reset, translation, error] as const;
}

export function usePostTranslations(
  repo: RepositoryType | EmailRepositoryType,
  options?: MutationHookOptions<
    { translations: TranslationTypes },
    { input: Partial<TranslationType>[] }
  >,
) {
  const [mutation, { data, error, loading }] = useMutation<
    { translations: TranslationTypes },
    { input: Partial<TranslationType>[] }
  >(
    repo === REPOSITORY_VOICE
      ? CREATE_NATIVE_TRANSLATIONS
      : CREATE_TRANSLATIONS,
    options,
  );
  const [updateTranslations, progress, reset] = useGenericProgress<
    { translations: TranslationTypes },
    { input: Partial<TranslationType>[] }
  >(mutation, { loading, error });

  const translation = data?.translations;
  return [updateTranslations, progress, reset, translation, error] as const;
}

export function useRequestBulkAction() {
  const [mutation, { data, error, loading }] = useMutation<
    { requestBulkAction: AuctionRequest },
    {
      account_id: number;
      action_type: string;
      careprovider_id: number;
      input: { id: number }[] | undefined;
    }
  >(REQUEST_BULK_ACTION, {
    refetchQueries: ["careproviderRequestsNew"],
  });
  const [requestBulkAction, progress, reset] = useGenericProgress<
    { requestBulkAction: AuctionRequest },
    {
      account_id: number;
      action_type: string;
      careprovider_id: number;
      input: { id: number }[] | undefined;
    }
  >(mutation, { loading, error });

  return [requestBulkAction, progress, reset, data, error] as const;
}

export function useAddPriorityProvider({
  careseekerId,
  onCompleted,
}: {
  careseekerId: number;
} & MutationHookOptions<
  { notifySocialWorker: unknown },
  { careseekerId: number; input: number }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { notifySocialWorker: unknown },
      { careseekerId: number; input: number }
    >(ADD_PRIORITY_PROVIDER, {
      variables: { careseekerId },
      refetchQueries: ["getCareseeker"],
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useDeletePriorityProvider({
  careproviderId,
  careseekerId,
  onCompleted,
}: {
  careproviderId: number;
  careseekerId: number;
} & MutationHookOptions<
  { notifySocialWorker: unknown },
  {
    careproviderId: number;
    careseekerId: number;
    input: Partial<Careprovider>;
  }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { notifySocialWorker: unknown },
      {
        careproviderId: number;
        careseekerId: number;
        input: Partial<Careprovider>;
      }
    >(DELETE_PRIORITY_PROVIDER, {
      variables: { careseekerId, careproviderId },
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useNotifySocialWorker({
  onCompleted,
  patientId,
}: {
  patientId: number;
} & MutationHookOptions<
  { notifySocialWorker: unknown },
  { id: number; input: { message: string } }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { notifySocialWorker: unknown },
      { id: number; input: { message: string } }
    >(NOTIFY_SOCIAL_WORKER, {
      variables: { id: patientId },
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useNotifyReceiverAssignee({
  auctionRequestId,
  onCompleted,
}: {
  auctionRequestId: number;
} & MutationHookOptions<
  { notifySocialWorker: unknown },
  { id: number; input: { message: string } }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { notifySocialWorker: unknown },
      { id: number; input: { message: string } }
    >(NOTIFY_RECEIVER_RESPONSIBLE_PERSON, {
      variables: { id: auctionRequestId },
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export type RejectedProvider = {
  careprovider: Careprovider;
  solution: number;
};

export function useSendFaxForAuctionRequest() {
  const [mutate, { data, error, loading }] = useMutation<
    { fax: FaxResponse },
    { auctionRequestId: number; input: { content: string; fax_type: number } }
  >(SEND_FAX_FOR_AUCTION_REQUEST);
  const [sendFax, mutationProgress, resetProgress] = useGenericProgress<
    { fax: FaxResponse },
    { auctionRequestId: number; input: { content: string; fax_type: number } }
  >(mutate, { loading, error });

  return [sendFax, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateAuction(patient?: Patient) {
  const encryptionContext = useApolloEncryptionContext({
    decrypt: true,
  });

  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<{ auction: Auction }, { input: Partial<Auction> }>(
      CREATE_AUCTION,
      {
        context: { encryptionContext },
        update: (proxy, newAuctionData) => {
          const { auction: newAuction } = newAuctionData?.data || {};
          if (!patient || !newAuction) return;

          const patientWithNewAuction = {
            ...patient,
            auctions: [...(patient.auctions || []), newAuction],
          };

          proxy.writeQuery({
            query: GET_PATIENT,
            variables: { id: patient.id },
            data: {
              patient: patientWithNewAuction,
            },
          });
        },
      },
    );

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateCompany({
  id,
  onCompleted,
  onError,
  token,
}: {
  id: number;
  token?: string;
} & MutationHookOptions<
  { company: Company },
  {
    id: number;
    input: Pick<Company, "ik_numbers" | "parent_company_id">;
    token?: string;
  }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { company: Company },
      {
        id: number;
        input: Pick<Company, "ik_numbers" | "parent_company_id">;
        token?: string;
      }
    >(token ? UPDATE_COMPANY_TOKEN : UPDATE_COMPANY, {
      variables: { id, token },
      onCompleted,
      onError,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateAccount({
  id,
  onCompleted,
}: {
  id?: number;
} & MutationHookOptions<
  { account: Account },
  { id?: number; input: Partial<Account>; jwt?: string }
> = {}) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { account: Account },
      { id?: number; input: Partial<Account>; jwt?: string }
    >(UPDATE_ACCOUNT, {
      variables: { id },
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateCareproviderAccount({
  accountId,
  careproviderId,
  onCompleted,
  token,
}: {
  accountId?: number;
  careproviderId: number;
  token?: string;
} & MutationHookOptions<
  { careproviderAccount: Account },
  {
    accountId?: number;
    careproviderId: number;
    input?: Partial<Account>;
    token?: string;
  }
>) {
  const [apolloMutation, { data, error, loading }] = useMutation<
    { careproviderAccount: Account },
    {
      accountId?: number;
      careproviderId: number;
      input?: Partial<Account>;
      token?: string;
    }
  >(token ? UPDATE_CAREPROVIDER_ACCOUNT_TOKEN : UPDATE_CAREPROVIDER_ACCOUNT, {
    variables: { careproviderId, accountId, token },
    onCompleted,
  });
  const [updateAccount, mutationProgress, resetProgress] = useGenericProgress<
    { careproviderAccount: Account },
    {
      accountId?: number;
      careproviderId: number;
      input?: Partial<Account>;
      token?: string;
    }
  >(apolloMutation, { loading, error });

  return [updateAccount, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateReceiverSessionKey(
  patientId: number,
  receiverId: number,
) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { sessionKeys: unknown },
      {
        id: number;
        input: {
          account_id?: number;
          patient_id: number;
          session_key?: string;
        }[];
      }
    >(UPDATE_RECEIVER_SESSION_KEYS, {
      variables: { id: receiverId },
      refetchQueries: [
        {
          query: GET_PATIENT,
          variables: { id: patientId },
        },
      ],
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateReceiversSessionKeys(
  options?: MutationHookOptions<
    { sessionKeys: SessionKey[] },
    {
      id: number | undefined;
      input: {
        account_id?: number;
        patient_id: number;
        session_key?: string;
      }[];
    }
  >,
) {
  const [mutation, { data, error, loading }] = useMutation<
    { sessionKeys: SessionKey[] },
    {
      id: number | undefined;
      input: {
        account_id?: number;
        patient_id: number;
        session_key?: string;
      }[];
    }
  >(UPDATE_RECEIVER_SESSION_KEYS, options);

  const [updateSessionKeys, progress, reset] = useGenericProgress<
    { sessionKeys: SessionKey[] },
    {
      id: number | undefined;
      input: {
        account_id?: number;
        patient_id: number;
        session_key?: string;
      }[];
    }
  >(mutation, { loading, error });

  const sessionKeys = data?.sessionKeys;
  return [updateSessionKeys, progress, reset, sessionKeys, error] as const;
}

export function useUpdateInsurance({
  insuranceId,
  onCompleted,
}: {
  insuranceId: number;
} & MutationHookOptions<
  { insurance: Insurance },
  { id: number; input: { message: string } }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { insurance: Insurance },
      { id: number; input: { message: string } }
    >(UPDATE_INSURANCE, {
      variables: { id: insuranceId },
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useAddAccountRoles({
  careproviderId,
  careseekerId,
  ...options
}: MutationHookOptions<{ accountRole: Account }> & {
  careproviderId?: number;
  careseekerId?: number;
} = {}) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { accountRole: Account },
      {
        input: {
          account_id: number;
          careprovider_id?: number;
          careseeker_id?: number;
          role_value: number;
        }[];
      }
    >(ADD_ACCOUNT_ROLES, {
      update: (cache, { data }) => {
        const queryData = (() => {
          if (careseekerId)
            return {
              query: GET_CARESEEKER_ACCOUNTS,
              variables: { id: careseekerId },
            };
          if (careproviderId)
            return {
              query: GET_RECEIVER_ACCOUNTS,
              variables: { id: careproviderId },
            };

          return {
            query: GET_ACCOUNTS,
            variables: {},
          };
        })();

        const response = cache.readQuery<{ accounts: Account[] }>({
          query: queryData.query,
          variables: queryData.variables,
        });

        const accounts = response?.accounts;
        const newAccount = data?.accountRole;
        const updatedAccounts = accounts?.map((account) =>
          newAccount?.id === account.id
            ? { ...account, roles: { ...account.roles, ...newAccount.roles } }
            : account,
        );

        if (newAccount && accounts) {
          cache.writeQuery({
            query: queryData.query,
            variables: queryData.variables,
            data: {
              accounts: updatedAccounts,
            },
          });
        }
      },
      ...options,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useDeleteAccountRoles() {
  const [apolloMutation, { data, error, loading }] = useMutation<
    {
      accountRole: {
        accountId: number;
        queryVars: string;
        role_value: number;
      };
    },
    { accountId: number; queryVars: string; role_value: number }
  >(DELETE_ACCOUNT_ROLE);

  const [deleteRole, mutationProgress, resetProgress] = useGenericProgress<
    {
      accountRole: {
        accountId: number;
        queryVars: string;
        role_value: number;
      };
    },
    { accountId: number; queryVars: string; role_value: number }
  >(apolloMutation, { loading, error });

  return [deleteRole, mutationProgress, resetProgress, data, error] as const;
}

export function useAddCareproviderAccountRoles({
  careproviderId,
  onCompleted,
}: {
  careproviderId: number;
} & MutationHookOptions<
  { careproviderAccountRole: unknown },
  {
    careproviderId: number;
    input: {
      account_id: number;
      careprovider_id: number;
      role_value: number;
    }[];
  }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { careproviderAccountRole: unknown },
      {
        careproviderId: number;
        input: {
          account_id: number;
          careprovider_id: number;
          role_value: number;
        }[];
      }
    >(ADD_CAREPROVIDER_ACCOUNT_ROLES, {
      variables: { careproviderId },
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useDeleteCareproviderAccountRole({
  accountId,
  careproviderId,
  onCompleted,
  queryVars,
  role_value,
}: {
  accountId: number | undefined;
  careproviderId: number;
  queryVars: string;
  role_value: number;
} & MutationHookOptions<
  { careproviderAccountRole: unknown },
  {
    accountId: number | undefined;
    careproviderId: number;
    queryVars: string;
    role_value: number;
  }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { careproviderAccountRole: unknown },
      {
        accountId: number | undefined;
        careproviderId: number;
        queryVars: string;
        role_value: number;
      }
    >(DELETE_CAREPROVIDER_ACCOUNT_ROLE, {
      variables: { careproviderId, accountId, role_value, queryVars },
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateInsurance({
  onCompleted,
}: MutationHookOptions<
  { insurance: Insurance },
  { input: { message: string } }
> = {}) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { insurance: Insurance },
      { input: { message: string } }
    >(CREATE_INSURANCE, {
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateCareseeker({
  onCompleted,
}: MutationHookOptions<
  { careseeker: Careseeker },
  { input: Partial<Careseeker> }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { careseeker: Careseeker },
      { input: Partial<Careseeker> }
    >(CREATE_CARESEEKER, {
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

const updateCompanyCareproviders: MutationUpdaterFunction<
  { careprovider: Careprovider },
  OperationVariables,
  any,
  ApolloCache<{}>
> = (proxy, { data }) => {
  const careprovider = data?.careprovider;
  const companyId = careprovider?.company?.id;
  if (companyId) {
    const getCompany = proxy.readQuery({
      query: GET_COMPANY,
      variables: { id: companyId },
    }) as {
      company: Company;
    } | null;

    const company = getCompany?.company;
    if (company)
      proxy.writeQuery({
        query: GET_COMPANY,
        variables: { id: companyId },
        data: {
          company: {
            ...company,
            careproviders: [...(company?.careproviders ?? []), careprovider],
          },
        },
      });
  }
};

export function useCreateCareprovider({
  onCompleted,
}: MutationHookOptions<{ careprovider: Careprovider }> = {}) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { careprovider: Careprovider },
      { input: Partial<Careprovider> }
    >(CREATE_CAREPROVIDER, {
      onCompleted,
      update: updateCompanyCareproviders,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateCompany({
  onCompleted,
}: MutationHookOptions<{ company: Company }>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<{ company: Company }, { input: Partial<Company> }>(
      CREATE_COMPANY,
      {
        onCompleted,
      },
    );

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useGetAccountWithinCareseekerGroup({
  careseekerId,
  ...options
}: Pick<
  MutationHookOptions<{ account: Account }>,
  "onCompleted" | "onError"
> & {
  careseekerId: number;
}) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { account: Account },
      { careseekerId: number; input: { email: string } }
    >(GET_ACCOUNT_WITHIN_CARESEEKER_GROUP, {
      variables: { careseekerId },
      ...options,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateAccountForCareseekerMutation({
  careseekerId,
  ...options
}: Pick<
  MutationHookOptions<
    { account: Account },
    { careseekerId?: number; timestamp?: string }
  >,
  "onCompleted" | "update"
> & { careseekerId?: number }) {
  const translations = useTranslations();
  const toast = useToast();
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { account: Account },
      { careseekerId?: number; input: Partial<Account>; timestamp?: string }
    >(CREATE_ACCOUNT_FOR_CARESEEKER, {
      variables: { careseekerId },
      refetchQueries: ["getAccountsForCareseeker"],
      onError: (err) => {
        toast({
          color: "danger",
          message: getCreateAccountErrorMessage({ error: err, translations }),
        });
      },
      ...options,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateAccountForCareseekerMutation({
  accountId,
  careseekerId,
  ...options
}: Omit<
  MutationHookOptions<
    { account: Account },
    { accountId?: number; careseekerId?: number; timestamp?: string }
  >,
  "refetchQueries"
> & {
  accountId?: number;
  careseekerId?: number;
  timestamp?: string;
}) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { account: Account },
      {
        accountId?: number;
        careseekerId?: number;
        input: Partial<Account>;
        timestamp?: string;
      }
    >(UPDATE_ACCOUNT_FOR_CARESEEKER, {
      variables: { accountId, careseekerId },
      refetchQueries: ["getAccountsForCareseeker"],
      ...options,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateAccount({
  ...options
}: MutationHookOptions<{ account: Account }>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<{ account: Account }, { input: Partial<Account> }>(
      CREATE_ACCOUNT,
      { ...options },
    );

  // TODO make sure data.receiverNote is get correctly on use
  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateCareseeker({
  id,
  onCompleted,
  onError,
}: {
  id: number;
} & MutationHookOptions<{ careseeker: Careseeker }>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { careseeker: Careseeker },
      { id: number; input: Partial<Careseeker> }
    >(UPDATE_CARESEEKER, {
      variables: { id },
      onCompleted,
      onError,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateCareprovider({
  id,
  onCompleted,
  onError,
  token,
}: {
  id: number;
  token?: string;
} & MutationHookOptions<{ careprovider: Careprovider }>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { careprovider: Careprovider },
      { id: number; input: Partial<Careprovider> }
    >(token ? UPDATE_CAREPROVIDER_TOKEN : UPDATE_CAREPROVIDER, {
      variables: { id },
      onCompleted,
      onError,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useArchivePatientMutation({
  id,
  onCompleted,
  onError,
}: {
  id: number;
} & MutationHookOptions) {
  return useGenericMutation<
    { patient: Patient },
    { id: number; input: { archived: boolean; id: number } }
  >(ARCHIVE_PATIENT, {
    variables: { id },
    onCompleted,
    onError,
  });
}

export function useGetDynamicAuctionFormSteps({
  revision,
}: {
  revision: number;
}) {
  const [mutation, { data, error, loading }] = useMutation<
    { formStepsConfiguration: string[] },
    Partial<{ input: ReturnType<typeof getAuctionForDynamo>; revision: number }>
  >(GET_DYNAMIC_AUCTION_ASSESSMENT_STEPS, { variables: { revision } });
  const [updateFormScopes, queryProgress, resetProgress] = useGenericProgress<
    { formStepsConfiguration: string[] },
    Partial<{ input: ReturnType<typeof getAuctionForDynamo>; revision: number }>
  >(mutation, { loading, error });

  return [updateFormScopes, queryProgress, resetProgress, data, error] as const;
}

export function useGetDynamicAuctionFormConfiguration({
  isOnePageAssessment,
  revision,
}: {
  isOnePageAssessment?: boolean;
  revision: number;
}) {
  const [mutation, { data, error, loading }] = useMutation<
    Record<
      "auctionFormAllFields" | "auctionFormConfiguration",
      string[] | { fields: string[]; step_name: string }[]
    >,
    Partial<{
      formName: keyof PrecomputePatientWhitelist;
      input: ReturnType<typeof getAuctionForDynamo>;
      revision: number | undefined;
    }>
  >(
    isOnePageAssessment
      ? GET_DYNAMIC_AUCTION_FORM_ALL_FIELDS
      : GET_DYNAMIC_AUCTION_FORM_CONFIGURATION,
    {
      variables: { revision: isOnePageAssessment ? undefined : revision },
    },
  );
  const [updateFormScopes, queryProgress, resetProgress] = useGenericProgress<
    Record<
      "auctionFormAllFields" | "auctionFormConfiguration",
      string[] | { fields: string[]; step_name: string }[]
    >,
    Partial<{
      formName: keyof PrecomputePatientWhitelist;
      input: ReturnType<typeof getAuctionForDynamo>;
      revision: number | undefined;
    }>
  >(mutation, {
    loading,
    error,
  });

  return [updateFormScopes, queryProgress, resetProgress, data, error] as const;
}

export function useOauthAuthentication({
  careproviderId,
  integrationId,
  onCompleted,
  onError,
}: {
  careproviderId: number;
  integrationId: number;
} & MutationHookOptions<{ onpremiseConfig: "success" }>) {
  return useGenericMutation<
    { onpremiseConfig: "success" },
    {
      careproviderId: number;
      input: OauthAuthenticationInput;
      integrationId: number;
    }
  >(CONNEXT_AUTHENTICATION, {
    variables: { careproviderId, integrationId },
    onCompleted,
    onError,
    refetchQueries: [
      { query: GET_CAREPROVIDER({}), variables: { id: careproviderId } },
    ],
  });
}

export function useUpsertOnpremiseConfig({
  configId,
  onCompleted,
  onError,
}: {
  configId: number;
} & MutationHookOptions<{ onpremiseConfig: OnpremiseConfig }>) {
  const isNew = configId === -1;
  const UPSERT_ONPREMISE_CONFIG = isNew
    ? CREATE_ONPREMISE_CONFIG
    : UPDATE_ONPREMISE_CONFIG;

  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { onpremiseConfig: OnpremiseConfig },
      { configId: number; input: OnpremiseConfigInput }
    >(UPSERT_ONPREMISE_CONFIG, {
      variables: isNew ? undefined : { configId },
      onCompleted,
      onError,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateOnPremImport({
  careseekerId,
  onCompleted,
  onError,
}: {
  careseekerId: number;
} & MutationHookOptions<{ onpremiseConfig: OnPremImport }>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { onpremiseConfig: OnPremImport },
      { careseekerId: number; input: Partial<OnPremImport> }
    >(CREATE_ONPREMISE_IMPORT, {
      variables: { careseekerId },
      onCompleted,
      onError,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useGetAddressMutation(
  options?: MutationHookOptions<{ address: AddressCircle }>,
) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { address: AddressCircle },
      { input: Partial<AddressCircle> }
    >(GET_ADDRESS, {
      onCompleted: options?.onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateFeedbackPatientInfoMissing({
  auctionRequestId,
  careproviderId,
  onCompleted,
  refetchPatientEvents,
}: {
  auctionRequestId: number | null | undefined;
  careproviderId: number | null | undefined;
  refetchPatientEvents?: boolean;
} & MutationHookOptions<
  { feedbackPatientInfoMising: unknown },
  {
    auctionRequestId: number | null | undefined;
    careproviderId: number | null | undefined;
    input: string;
  }
>) {
  const refetchQueries = [];

  if (auctionRequestId && refetchPatientEvents) {
    refetchQueries.push(
      getAuctionRequestEventQuery({
        auctionRequestId,
      }),
    );
  }
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { feedbackPatientInfoMising: unknown },
      {
        auctionRequestId: number | null | undefined;
        careproviderId: number | null | undefined;
        input: string;
      }
    >(FEEDBACK_PATIENT_INFO_MISSING, {
      variables: { careproviderId, auctionRequestId },
      onCompleted,
      refetchQueries: refetchQueries.length ? refetchQueries : [],
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateSenderReceiverNote({
  careproviderId,
  careseekerId,
  onCompleted,
}: {
  careproviderId: number | null | undefined;
  careseekerId: number | null | undefined;
} & MutationHookOptions<{ senderReceiverNote: SenderReceiverNote }>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { senderReceiverNote: SenderReceiverNote },
      {
        careproviderId: number | null | undefined;
        careseekerId: number | null | undefined;
        input: Partial<SenderReceiverNote>;
      }
    >(UPDATE_SENDER_RECEIVER_NOTE, {
      variables: { careseekerId, careproviderId },
      onCompleted,
      update: (proxy, { data }) => {
        proxy.writeQuery({
          query: GET_SENDER_RECEIVER_NOTE,
          variables: { careseekerId, careproviderId },
          data,
        });
      },
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateAssociations() {
  const [apolloMutation, { data, error, loading }] = useMutation<
    { association: null },
    { input: ProviderBlackListing }
  >(UPDATE_ASSOCIATIONS);
  const [mutation, mutationProgress, resetProgress] = useGenericProgress<
    { association: null },
    { input: ProviderBlackListing }
  >(apolloMutation, { loading, error });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUploadCareproviderDpa({
  careproviderId,
  fileType,
}: {
  careproviderId: number;
  fileType: number;
}) {
  const { setLegalDocs } = useLegalDocumentsContext();
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { uploadDpaFile: DPAFileType },
      {
        careproviderId: number;
        fileType: ValueOf<typeof FILE_TYPES>;
        input: Partial<DPAFileType>;
      }
    >(UPLOAD_CAREPROVIDER_DPA, {
      variables: {
        careproviderId,
        fileType,
      },
      onCompleted: () => {
        setLegalDocs({ careproviderId });
      },
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useDeleteCareproviderDpa({
  careproviderId,
}: {
  careproviderId: number;
}) {
  const { setLegalDocs } = useLegalDocumentsContext();
  const [mutation, { data, error, loading }] = useMutation<
    { deleteDpaFile: CareproviderDpaFile },
    { careproviderId: number }
  >(DELETE_CAREPROVIDER_DPA, {
    variables: { careproviderId },
    onCompleted: () => setLegalDocs({ careproviderId }),
    update: (cache) => {
      const query = gql`
          query Careprovider {
            careprovider {
              id
              ${careproviderDpa()}
            }
          }
        `;

      cache.writeQuery({
        query,
        data: {
          careprovider: {
            id: careproviderId,
            data_processing_agreement: null,
            __typename: "Careprovider",
          },
        },
      });
    },
  });

  const [deleteDpaFile, mutationProgress, resetProgress] = useGenericProgress<
    { deleteDpaFile: CareproviderDpaFile },
    { careproviderId: number }
  >(mutation, { loading, error });

  return [deleteDpaFile, mutationProgress, resetProgress, data, error] as const;
}

export function useUploadCareproviderFile({
  careproviderId,
  careproviderToken,
  fileType,
}: {
  careproviderId: number;
  careproviderToken?: string;
  fileType: number;
}) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { uploadFile: CareproviderFile },
      {
        careproviderId: number;
        fileType: number;
        input: Partial<CareproviderFile>;
        params: string;
      }
    >(UPLOAD_CAREPROVIDER_FILE, {
      variables: {
        careproviderId,
        params: careproviderToken ? `?token=${careproviderToken}` : "",
        fileType,
      },
      refetchQueries: [
        {
          query: GET_CAREPROVIDER_FILES,
          variables: {
            careproviderId,
            params: careproviderToken ? `?token=${careproviderToken}` : "",
          },
        },
      ],
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useDeleteCareproviderFile({
  careproviderId,
  careproviderToken,
}: {
  careproviderId: number;
  careproviderToken?: string;
}) {
  const [mutation, { error, loading }] = useMutation<
    { deleteFile: CareproviderFile },
    Partial<{
      careproviderId: number;
      fileId: number;
      params: string;
    }>
  >(DELETE_CAREPROVIDER_FILE, {
    variables: {
      careproviderId,
      params: careproviderToken ? `?token=${careproviderToken}` : "",
    },
    refetchQueries: [
      {
        query: GET_CAREPROVIDER_FILES,
        variables: {
          careproviderId,
          params: careproviderToken ? `?token=${careproviderToken}` : "",
        },
      },
    ],
  });
  const [deleteFile, mutationProgress, resetProgress] = useGenericProgress<
    { deleteFile: CareproviderFile },
    Partial<{
      careproviderId: number;
      fileId: number;
      params: string;
    }>
  >(mutation, { loading, error });

  return [deleteFile, mutationProgress, resetProgress, error] as const;
}

export function useCreateUserManagementFile() {
  const [mutation, mutationProgres, resetProgress, data, error] =
    useGenericMutation<
      { file: SenderUserManagementFile | undefined },
      { input: CreateUserManagmentFileParams }
    >(CREATE_USER_MANAGEMENT_FILE, {
      refetchQueries: ["getCareseeker", "getAccounts"],
    });

  return [mutation, mutationProgres, resetProgress, data?.file, error] as const;
}

export function useDeleteUserManagementFile({ id }: { id: number }) {
  const [mutation, mutationProgres, resetProgress, data, error] =
    useGenericMutation<
      { file: SenderUserManagementFile | undefined },
      { id: number }
    >(DELETE_USER_MANAGEMENT_FILE, {
      variables: { id },
      refetchQueries: ["getCareseeker", "getAccounts"],
    });

  return [mutation, mutationProgres, resetProgress, data?.file, error] as const;
}

export const useCreateFileMutation = ({ params }: { params: FileParams }) => {
  const [mutation, mutationProgres, resetProgress, data, error] =
    useGenericMutation<
      { file: Filev2 },
      {
        input: {
          careprovider_id?: number;
          careseeker_id?: number;
          category?: Filev2["category"];
          category_other?: string;
          file_type?: Filev2["file_type"];
          kis_document_id_string?: Filev2["kis_document_id_string"];
        };
        params: FileParams;
      }
    >(CREATE_FILE, {
      variables: { params },
    });

  return [mutation, mutationProgres, resetProgress, data?.file, error] as const;
};

export function useUpdateFileMutation({
  id,
  params,
}: {
  id: number;
  params: FileParams;
}) {
  const { eventsQuery } = useAuctionRequestEventsContext();
  const [mutation, progress, reset, data, error] = useGenericMutation<
    { file: Filev2 | undefined },
    {
      id: Filev2["id"];
      input: {
        category?: FileCategory;
        file_content?: EncryptedField;
        file_name?: EncryptedField;
        kis_document_id_string?: Filev2["kis_document_id_string"];
        share_mode?: FileShareMode;
      };
      params: FileParams;
    }
  >(UPDATE_FILE, {
    variables: { id, params },
    refetchQueries: eventsQuery ? [eventsQuery] : undefined,
  });

  return [mutation, progress, reset, data?.file, error] as const;
}

export function useDeleteFileMutation({
  id,
  onCompleted,
  onError,
  params,
}: { id: number; params: FileParams } & MutationHookOptions<{
  file: Filev2 | undefined;
}>) {
  const { eventsQuery } = useAuctionRequestEventsContext();
  const [mutation, progress, reset, data, error] = useGenericMutation<
    { file: Filev2 | undefined },
    { id: number; params: FileParams }
  >(DELETE_FILE, {
    variables: { id, params },
    onCompleted,
    onError,
    refetchQueries: ["getFiles", ...(eventsQuery ? [eventsQuery] : [])],
  });

  return [mutation, progress, reset, data?.file, error] as const;
}

export function useRequestActionMutation({
  actionType,
  onCompleted,
  onError,
  refetchQueries,
  requestId,
}: {
  actionType: RequestActionType | UndoActionType;
  refetchQueries?: BaseMutationOptions["refetchQueries"];
  requestId?: number;
} & MutationHookOptions<{ requestAction: AuctionRequest }>) {
  const refetch = useRefetchOnConflict();
  const encryptionContext = useApolloEncryptionContext({ decrypt: true });

  const memoisedMutation = useMemo(
    () => USE_REQUEST_ACTION(actionType),
    [actionType, requestId],
  );

  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { requestAction: AuctionRequest },
      {
        id: number;
        input: {
          context: AnyObject;
          ref: string;
          request_id: number;
        };
      }
    >(memoisedMutation, {
      ...(refetchQueries ? { refetchQueries } : {}),
      onError: (err) => {
        if (requestId && requestId > 0) {
          refetch({
            error: err,
            query: GET_AUCTIONREQUEST,
            variables: { id: requestId, ref: getReferrer() },
            context: { encryptionContext },
          });
        } else {
          console.error(
            `[DEV-14068] [useRefetchOnConflict] tried to access bad request id ${requestId}`,
          );
        }
        onError?.(err);
      },

      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useSearchActionMutation({
  actionType,
  refetchQueries,
}: {
  actionType: SearchActionsWithHandlers;
  refetchQueries?: BaseMutationOptions["refetchQueries"];
}) {
  const encryptionContext = useApolloEncryptionContext();

  const memoisedMutation = useMemo(
    () => USE_SEARCH_ACTION(actionType),
    [actionType],
  );

  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { auction: Auction },
      {
        auctionId: number;
        input: {
          auction_id: number;
          context: AnyObject;
        };
      }
    >(memoisedMutation, {
      context: { encryptionContext },
      ...(refetchQueries ? { refetchQueries } : {}),
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useAcceptTermsAndConditions({
  careprovider,
}: {
  careprovider: Careprovider | undefined;
}) {
  const { setLegalDocs } = useLegalDocumentsContext();
  const { getDocument } = useLegalDocuments({
    careproviderId: careprovider?.id,
  });
  const tcVersion = getDocument(DOCUMENT_TERMS_AND_CONDITIONS)?.version;

  const [update, progress] = useUpdateCareprovider({
    id: careprovider?.id ?? -1,
    onCompleted: () => {
      setLegalDocs({ careproviderId: careprovider?.id });
    },
  });

  const updateTC = () => {
    if (!careprovider) {
      console.error("careprovider undefined in useAcceptTermsAndConditions");
      return null;
    }

    if (!tcVersion) {
      console.error(
        "document version undefined in useAcceptTermsAndConditions",
      );
    }

    return update({
      terms_and_conditions: {
        date: getUnixTime(new Date()),
        version: tcVersion,
      },
    });
  };

  return [updateTC, progress] as const;
}

export function useAcceptDPA({
  careproviderId,
  isChurchOwned,
}: {
  careproviderId: number;
  isChurchOwned: boolean;
}) {
  const { getDocument } = useLegalDocuments({
    careproviderId,
  });
  const dpaVersion = getDocument(DOCUMENT_DATA_PROCESSING_AGREEMENT)?.version;

  const [uploadDpaFile, mutationProgress] = useUploadCareproviderDpa({
    careproviderId,
    fileType: FILE_TYPES.DOCUMENTS,
  });
  const updateDPA = useCallback(
    (input?: Partial<DPAFileType>) => {
      if (!dpaVersion) {
        console.error("document version undefined in useAcceptDPA");
        return null;
      }

      return uploadDpaFile({
        ...input,
        church_owned: isChurchOwned,
        version: dpaVersion,
      });
    },
    [uploadDpaFile, dpaVersion],
  );
  return [updateDPA, mutationProgress] as const;
}

export function usePostEncryptionJob() {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { encryptionJob: null },
      { auctionId: number; id: number; input: EncryptionJobRequest }
    >(POST_ENCRYPTION_JOB);

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateEncryptionContext() {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { encryptionContext: EncryptionContext },
      {
        input: {
          [x: string]: string | number | undefined;
          seald_display_id?: string;
          seald_id: string;
          seald_type: number;
        };
      }
    >(CREATE_ENCRYPTION_CONTEXT);

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useInitiateSealdIdentity() {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { sealdIdentity: SealdIdentityPayload },
      { input: Partial<SealdIdentityInput> }
    >(INITIATE_SEALD_IDENTITY, {});

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateBrowserSubscription({
  id,
  onCompleted,
}: {
  id?: number;
} & MutationHookOptions<
  { browserSubscription: BrowserSubscription },
  {
    id?: number;
    input: {
      endpoint?: string;
      expirationTime?: number | null | undefined;
      id_token: string | null;
      keys?: Record<string, string> | undefined;
    };
  }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { browserSubscription: BrowserSubscription },
      {
        id?: number;
        input: {
          endpoint?: string;
          expirationTime?: number | null | undefined;
          id_token: string | null;
          keys?: Record<string, string> | undefined;
        };
      }
    >(UPDATE_BROWSER_SUBSCRIPTION, {
      variables: { id },
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useStartElectiveFlow() {
  return useGenericMutation<{ electiveFlow: null }>(START_ELECTIVE_FLOW);
}

export function useDeleteSealdAccess() {
  return useGenericMutation<
    { sealdIdentity: null },
    { input: SealdAccessPayload }
  >(DELETE_SEALD_ACCESS, {});
}

export function useCreateReverseSearch({
  careproviderId,
  onCompleted,
  onError,
}: {
  careproviderId: number;
} & MutationHookOptions<{ reverseSearch: ReverseSearch }>) {
  return useGenericMutation<
    { reverseSearch: ReverseSearch },
    {
      careproviderId: number;
      input: { careprovider_id: number; filter: ReceiverFilters };
    }
  >(CREATE_CAREPROVIDER_REVERSE_SEARCH, {
    onCompleted,
    onError,
    variables: { careproviderId },
    refetchQueries: [
      {
        query: GET_CAREPROVIDER_REVERSE_SEARCHES,
        variables: { careproviderId },
      },
    ],
  });
}

export function useDeleteReverseSearch({
  careproviderId,
  onCompleted,
  onError,
  searchId,
}: {
  careproviderId: number | undefined;
  searchId: number | undefined;
} & MutationHookOptions<{ deleteReverseSearch: ReverseSearch }>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { deleteReverseSearch: ReverseSearch },
      { careproviderId: number | undefined; searchId: number | undefined }
    >(DELETE_CAREPROVIDER_REVERSE_SEARCH, {
      variables: { searchId, careproviderId },
      onCompleted,
      onError,
      refetchQueries: [
        {
          query: GET_CAREPROVIDER_REVERSE_SEARCHES,
          variables: { careproviderId },
        },
      ],
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateReverseSearch({
  careproviderId,
  onCompleted,
  onError,
  searchId,
}: {
  careproviderId: number | undefined;
  searchId?: number;
} & MutationHookOptions<{ updateReverseSearch: ReverseSearch }>) {
  return useGenericMutation<
    { updateReverseSearch: ReverseSearch },
    {
      careproviderId: number | undefined;
      input: Partial<ReverseSearch>;
      searchId?: number;
    }
  >(UPDATE_CAREPROVIDER_REVERSE_SEARCH, {
    onCompleted,
    onError,
    variables: { searchId, careproviderId },
    refetchQueries: [
      {
        query: GET_CAREPROVIDER_REVERSE_SEARCHES,
        variables: { careproviderId },
      },
    ],
  });
}

export function useUpdateSearchCandidates() {
  return useGenericMutation<
    { searchCandidates: Candidates },
    { input: Partial<Auction> }
  >(UPDATE_SEARCH_CANDIDATES);
}

export function useCompleteProductTour(tourId: number | undefined) {
  return useGenericMutation<
    { productTour: ProductTourCompletion },
    {
      input: { completion_rate: number; step_id: number };
      tourId: number | undefined;
    }
  >(COMPLETE_PRODUCT_TOUR, { variables: { tourId } });
}

export function useCheckSealdSessions() {
  return useGenericMutation<
    { sealdSessions: SealdSessionsPayload },
    {
      auction_request_id?: number;
      file_id?: number;
      input: { [x: string]: number };
      patient_id?: number;
    }
  >(CHECK_SEALD_SESSIONS, {});
}

const useGetProviderSearchRedirectOnLogin = () => {
  const [search] = useSearchParams();
  const location = useLocation();

  search.delete("challenge");
  search.delete(REDIRECT_PARAM);

  // param used for if a user accessed an authenticated route whilst logged out
  // this is passed to the BE so they can create a log in link with tokens for the user
  // to access the page they intended from their email
  const zipcodeRedirect = search.get(RETURN_PARAM);
  if (zipcodeRedirect) {
    return zipcodeRedirect;
  }

  const searchString = search.toString();

  return encodeURIComponent(
    `${location.pathname}${searchString?.length ? `?${searchString}` : ""}`,
  );
};

export function useCreateProviderSearchAccount({
  onCompleted,
  onError,
}: MutationHookOptions<{ account: null }>) {
  const redirectTo = useGetProviderSearchRedirectOnLogin();
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { account: null },
      { input: { email: string }; redirectTo: string }
    >(CREATE_PROVIDER_SEARCH_ACCOUNT, {
      variables: { redirectTo },
      onError,
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useLoginProviderSearch({
  onCompleted,
  onError,
}: MutationHookOptions<{ account: null }>) {
  const redirectTo = useGetProviderSearchRedirectOnLogin();
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { account: null },
      { input: { email: string }; redirectTo: string }
    >(LOGIN_PROVIDER_SEARCH_ACCOUNT, {
      variables: { redirectTo },
      onError,
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreatePatientMutation() {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<{ patient: Patient }, { input: Partial<Patient> }>(
      CREATE_PATIENT,
    );

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateProviderSearchCareseeker() {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { careseeker: Careseeker },
      { input: Partial<Careseeker> }
    >(CREATE_PROVIDERSEARCH_CARESEEKER, {
      update: (proxy, { data }) => {
        const careseeker = data?.careseeker;
        const variables = { id: careseeker?.id };
        proxy.writeQuery({
          // should match query signiture in useLoggedCareseeker
          query: GET_CARESEEKER({ withAdminFields: true, withConfig: false }),
          variables,
          data: {
            careseeker,
          },
        });
      },
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useArchiveProviderSearchPatient({
  id,
  onCompleted,
}: {
  id: number;
} & MutationHookOptions<{ patient: Patient }>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<{ patient: Patient }, { id: number }>(
      ARCHIVE_PROVIDER_SEARCH_PATIENT,
      {
        variables: { id },
        refetchQueries: [
          "getProviderSearchRequestOverview",
          "getProviderSearchPatients",
        ],
        onCompleted,
      },
    );

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}
export function useSealdGroupReset({
  onCompleted,
  onError,
}: MutationHookOptions<
  { sealdGroup: null },
  { input: { seald_id: string | undefined } }
>) {
  return useGenericMutation<
    { sealdGroup: null },
    { input: { seald_id: string | undefined } }
  >(SEALD_GROUP_RESET, {
    refetchQueries: ["getAccounts", "getCareprovider"],
    onCompleted,
    onError,
  });
}

export function useUpdateConsultant({ id }: { id: number }) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { consultant: Consultant },
      { id: number; input: Partial<Consultant> }
    >(UPDATE_CONSULTANT, {
      variables: { id },
      refetchQueries: ["getConsultantEventPage"],
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useDeleteConsultantFile({
  consultantId,
}: {
  consultantId: number;
}) {
  const [mutation, { error, loading }] = useMutation<
    { deleteFile: CareproviderFile },
    { consultantId: number; fileId?: number }
  >(DELETE_CONSULTANT_FILE, {
    variables: {
      consultantId,
    },
    refetchQueries: [
      "getConsultantEventPage",
      {
        query: GET_CONSULTANT_FILES,
        variables: {
          consultantId,
        },
      },
    ],
  });

  const [deleteFile, mutationProgress, resetProgress] = useGenericProgress<
    { deleteFile: CareproviderFile },
    { consultantId: number; fileId?: number }
  >(mutation, { loading, error });

  return [deleteFile, mutationProgress, resetProgress, error] as const;
}

export function useUploadConsultantFile({
  consultantId,
}: {
  consultantId: number;
}) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      CareproviderFile,
      {
        consultantId: number;
        fileType: number;
        input: { content: string; file_name: string; file_type: number };
      }
    >(UPLOAD_CONSULTANT_FILE, {
      variables: {
        consultantId,
        fileType: FILE_TYPES.IMAGES,
      },
      refetchQueries: [
        "getConsultantEventPage",
        {
          query: GET_CONSULTANT_FILES,
          variables: {
            consultantId,
          },
        },
      ],
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateConsultant({
  onCompleted,
}: MutationHookOptions<
  { consultant: Consultant },
  { input: Partial<Consultant> }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { consultant: Consultant },
      { input: Partial<Consultant> }
    >(CREATE_CONSULTANT, {
      onCompleted,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useUpdateEmailTranslation(
  options?: MutationHookOptions<
    { translation: EmailTranslation },
    {
      id: string;
      input: {
        id: string;
        translation: FormValue;
      };
    }
  >,
) {
  const [mutation, { data, error, loading }] = useMutation<
    { translation: EmailTranslation },
    {
      id: string;
      input: {
        id: string;
        translation: FormValue;
      };
    }
  >(UPDATE_EMAIL_TRANSLATION, options);
  const [updateTranslation, progress, reset] = useGenericProgress<
    { translation: EmailTranslation },
    {
      id: string;
      input: {
        id: string;
        translation: FormValue;
      };
    }
  >(mutation, { loading, error });

  const translation = data?.translation;
  return [updateTranslation, progress, reset, translation, error] as const;
}

export function useCreateEmailTranslation(
  options?: MutationHookOptions<
    { translation: EmailTranslation },
    { input: EmailTranslation }
  >,
) {
  const [mutation, { data, error, loading }] = useMutation<
    { translation: EmailTranslation },
    { input: EmailTranslation }
  >(CREATE_EMAIL_TRANSLATION, options);

  const [updateTranslation, mutationProgress, resetProgress] =
    useGenericProgress<
      { translation: EmailTranslation },
      { input: EmailTranslation }
    >(mutation, {
      loading,
      error,
    });

  const mutate = useCallback(
    (input: EmailTranslation) => updateTranslation({ variables: { input } }),
    [updateTranslation],
  );

  const translation = data?.translation;
  return [mutate, mutationProgress, resetProgress, translation, error] as const;
}

export function useDeleteEmailTranslation(
  options?: MutationHookOptions<
    { translation: TranslationType },
    { id: string }
  >,
) {
  const [mutation, { data, error, loading }] = useMutation<
    { translation: TranslationType },
    { id: string }
  >(DELETE_EMAIL_TRANSLATION, options);
  const [mutate, progress, reset] = useGenericProgress<
    { translation: TranslationType },
    { id: string }
  >(mutation, { loading, error });

  const translation = data?.translation;
  return [mutate, progress, reset, translation, error] as const;
}

export function useSendTestEmail(
  options?: MutationHookOptions<
    { testEmail: SendEmail },
    {
      input: {
        country: Country;
        mail_type: number;
      };
    }
  >,
) {
  const [mutation, { error, loading }] = useMutation<
    { testEmail: SendEmail },
    {
      input: {
        country: Country;
        mail_type: number;
      };
    }
  >(SEND_TEST_EMAIL, options);
  const [sendEmail, progress, resetProgress] = useGenericProgress<
    {
      testEmail: SendEmail;
    },
    {
      input: {
        country: Country;
        mail_type: number;
      };
    }
  >(mutation, { loading, error });

  return [sendEmail, progress, resetProgress] as const;
}

export function useUpdateCapacity({
  careprovider,
  ...options
}: {
  careprovider: Careprovider;
} & MutationHookOptions<{ capacity: Capacity }>) {
  const [mutation, { error, loading }] = useMutation<
    { capacity: Capacity },
    Partial<{ id: number; input: Partial<Capacity> }>
  >(UPDATE_CAPACITY, {
    ...options,
    update(cache, { data }) {
      if (data?.capacity) {
        cache.writeQuery({
          query: GET_CAREPROVIDER({}),
          data: {
            careprovider: {
              ...careprovider,
              capacity: data.capacity,
              __typename: "Careprovider",
            },
          },
        });
      }
    },
  });
  const [mutate, mutationProgress] = useGenericProgress<
    { capacity: Capacity },
    Partial<{ id: number; input: Partial<Capacity> }>
  >(mutation, { loading, error });

  const confirmCapacity = () =>
    mutate({
      variables: {
        id: careprovider.id,
        input: {
          capacity_confirmed: true,
        },
      },
    });

  const updateCapacity = (capacity: Omit<Capacity, "capacity_confirmed">) =>
    mutate({
      variables: {
        id: careprovider.id,
        input: capacity,
      },
    });

  return { updateCapacity, confirmCapacity, mutationProgress };
}

export function useSaveBedrockPrompt() {
  const [mutation, progress, reset, data, error] = useGenericMutation<
    { prompt: BedrockPrompt | undefined },
    {
      input: Omit<BedrockPrompt, "id">;
    }
  >(SAVE_BEDROCK_PROMPT, {
    refetchQueries: ["getBedrockPlayground"],
  });

  return [mutation, progress, reset, data?.prompt, error] as const;
}

export function useSaveBedrockSchema() {
  const [mutation, progress, reset, data, error] = useGenericMutation<
    { schema: BedrockSchema | undefined },
    {
      input: Omit<BedrockSchema, "id">;
    }
  >(SAVE_BEDROCK_SCHEMAS, {
    refetchQueries: ["getBedrockPlayground"],
  });

  return [mutation, progress, reset, data?.schema, error] as const;
}

export function useSaveBedrockExample() {
  const [mutation, progress, reset, data, error] = useGenericMutation<
    { example: BedrockExample | undefined },
    {
      input: Omit<BedrockExample, "id">;
    }
  >(SAVE_BEDROCK_EXAMPLE, {
    refetchQueries: ["getBedrockPlayground"],
  });

  return [mutation, progress, reset, data?.example, error] as const;
}

export function useSaveBedrockResponse() {
  const [mutation, progress, reset, data, error] = useGenericMutation<
    { response: BedrockResponse | undefined },
    {
      input: BedrockResponsePayload;
    }
  >(SAVE_BEDROCK_RESPONSE, {
    refetchQueries: ["getBedrockPlayground"],
  });

  return [mutation, progress, reset, data?.response, error] as const;
}

export function useDeleteBedrockPrompt(id: number, onCompleted: () => void) {
  const [mutation, progress, reset, _data, error] = useGenericMutation<null>(
    DELETE_BEDROCK_PROMPT,
    {
      variables: { id },
      refetchQueries: ["getBedrockPlayground"],
      onCompleted,
    },
  );

  return [mutation, progress, reset, error] as const;
}

export function useDeleteBedrockSchema(id: number, onCompleted: () => void) {
  const [mutation, progress, reset, _data, error] = useGenericMutation<null>(
    DELETE_BEDROCK_SCHEMA,
    {
      variables: { id },
      refetchQueries: ["getBedrockPlayground"],
      onCompleted,
    },
  );

  return [mutation, progress, reset, error] as const;
}

export function useDeleteBedrockExample(id: number, onCompleted: () => void) {
  const [mutation, progress, reset, _data, error] = useGenericMutation<null>(
    DELETE_BEDROCK_EXAMPLE,
    {
      variables: { id },
      refetchQueries: ["getBedrockPlayground"],
      onCompleted,
    },
  );

  return [mutation, progress, reset, error] as const;
}

export function useDeleteBedrockResponse(id: number) {
  const [mutation, progress, reset, _data, error] = useGenericMutation<null>(
    DELETE_BEDROCK_RESPONSE,
    {
      variables: { id },
      refetchQueries: ["getBedrockPlayground"],
    },
  );

  return [mutation, progress, reset, error] as const;
}

export function useCareproviderAccountAssociation({
  id,
  onCompleted,
  onError,
  token,
}: MutationHookOptions<
  { accountAssociation: Careprovider },
  {
    id: number;
    input: { email: string };
    token?: string;
  }
> & {
  id: number;
  token?: string;
}) {
  return useGenericMutation<
    { accountAssociation: Careprovider },
    { input: { email: string } }
  >(CAREPROVIDER_ACCOUNT_ASSOCIATION, {
    variables: { id, token },
    onCompleted,
    onError,
  });
}

export function useForgotPassword({
  onCompleted,
  onError,
}: MutationHookOptions<
  undefined,
  {
    input: {
      app: AppType;
      email: string;
      onboarding_token: string | undefined;
    };
  }
>) {
  return useGenericMutation<
    undefined,
    {
      input: {
        app: AppType;
        email: string;
        onboarding_token: string | undefined;
      };
    }
  >(FORGOT_PASSWORD, {
    variables: {
      endpoint: "reset/initiate",
    },
    onCompleted,
    onError,
  });
}

export function useUpdateIkNumbers({
  id,
  onCompleted,
  onError,
  token,
}: {
  id: Careprovider["id"];
  token?: string;
} & MutationHookOptions<
  { company: Company },
  {
    id: Careprovider["id"];
    input: Pick<Company, "ik_numbers" | "id">;
    token?: string;
  }
>) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<
      { company: Company },
      {
        id: Careprovider["id"];
        input: Pick<Company, "ik_numbers" | "id">;
        token?: string;
      }
    >(UPDATE_IK_NUMBERS, {
      variables: { id, token },
      onCompleted,
      onError,
    });

  return [mutation, mutationProgress, resetProgress, data, error] as const;
}

export function useCreateAdditionalAuction(
  options?: MutationHookOptions<
    { auction: Auction },
    {
      input: {
        search_type: number;
      };
      patientId: number;
    }
  >,
) {
  const [mutation, { error, loading }] = useMutation<
    { auction: Auction },
    {
      input: {
        search_type: number;
      };
      patientId: number;
    }
  >(CREATE_ADDITIONAL_AUCTION, options);

  const [createAdditionalAuction, progress, resetProgress] = useGenericProgress<
    { auction: Auction },
    {
      input: {
        search_type: number;
      };
      patientId: number;
    }
  >(mutation, { loading, error });

  return [createAdditionalAuction, progress, resetProgress] as const;
}

export function useSendOnpremiseLogs({
  configId,
  ...rest
}: MutationHookOptions<undefined, { configId: number | undefined }> & {
  configId: number | undefined;
}) {
  const [mutation, mutationProgress, resetProgress, data, error] =
    useGenericMutation<undefined, { configId: number | undefined }>(
      SEND_ONPREMISE_LOGS,
      { variables: { configId }, ...rest },
    );

  return [mutationProgress, mutation, resetProgress, data, error] as const;
}
