import { getLoginArguments } from "apollo/mutations/login";
import { isLoading } from "apollo/utils";
import { useEnvContext } from "context/EnvContext";
import {
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  TRACK_EVENTS,
} from "core/consts";
import api from "core/model/api";
import { isSenderApp } from "core/model/config";
import { getQueryBooleanVariable } from "core/model/utils/urls";
import { QueryProgressActive, TokenInvalidReason } from "core/types";
import SpinnerPage, {
  SpinnerPageContainer,
} from "ds_legacy/components/Spinner";
import { useOnEnter } from "dsl/atoms/Routes";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import {
  PasswordResetFromEmailPage,
  PasswordResetFromEmailPageProps,
} from "dsl/ecosystems/PasswordResetFromEmailPage/PasswordResetFromEmailPage";
import {
  useCareproviderHandlerUrls,
  useCareseekerHandlerUrls,
} from "dsl/hooks/useNavigationHandlers";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  convertModelDefinition,
  SimpleFormRenderProps,
  valueDef,
} from "react-forms-state/src";
import { useNavigate, useParams } from "react-router-dom";
import { useTracking } from "react-tracking";
import { useLocale } from "translations";
import Translations from "translations/types";
import { getTimestamp } from "../Careseeker/UserManagementPage/useUserManagementFeature";
import { useVerifyToken } from "../PasswordResetPage/useVerifyToken";
import TokenExpired from "./token-expired";
import {
  PasswordChangeSuccessPage,
  PasswordChangeSuccessPageProps,
} from "dsl/ecosystems/PasswordChangePageV2/PasswordChangeSuccessPage";

type PasswordResetPageView = "default" | "success";

export type PasswordResetProps = {
  changePassword: () => Promise<void>;
  isActivation?: boolean;
  loading: QueryProgressActive;
  password: string | undefined;
  passwordConfirmation: string | undefined;
  setPassword: Dispatch<SetStateAction<string | undefined>>;
  setPasswordConfirmation: Dispatch<SetStateAction<string | undefined>>;
  toAuth: () => void;
  tokenInvalidReason: TokenInvalidReason | undefined;
};

// Define the regex for password complexity
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const modelDefinition = convertModelDefinition({
  ...valueDef("new_password"),
  ...valueDef("confirm_new_password"),
});

const isPasswordResetSuccess = (view: PasswordResetPageView) =>
  view === "success";

export function validatePassword(
  {
    confirm_new_password,
    new_password,
  }: { confirm_new_password: string | null; new_password: string | null },
  { translations }: { translations: Translations },
): true | Partial<Record<"new_password" | "confirm_new_password", string>> {
  let validation:
    | true
    | Partial<Record<"new_password" | "confirm_new_password", string>> = true;

  // Check if the password is complex enough
  if (!PASSWORD_REGEX.test(new_password ?? "")) {
    validation = {
      new_password: translations.login.errorNewPasswordInvalid,
    };
  }

  // Check if the passwords match
  if (new_password !== confirm_new_password) {
    validation = {
      ...(validation !== true ? validation : {}),
      confirm_new_password: translations.login.setNewPasswordNoMatch,
    };
  }

  // Check if the password is missing
  if (!new_password) {
    validation = {
      new_password: translations.login.loginPage.newPasswordValidation,
    };
  }

  // Check if the confirm password is missing
  if (!confirm_new_password) {
    validation = {
      ...(validation !== true ? validation : {}),
      confirm_new_password:
        translations.login.loginPage.confirmNewPasswordValidation,
    };
  }

  return validation;
}

export default function PasswordResetPageV2({
  authRoute,
}: {
  authRoute: string;
}) {
  useOnEnter();
  const { app, env } = useEnvContext();
  const locale = useLocale();
  const navigate = useNavigate();
  const toast = useToast();
  const { trackEvent } = useTracking();
  const [view, setView] = useState<PasswordResetPageView>("default");
  const { goToProductionEnv: goToProductionUrlReceiver } =
    useCareproviderHandlerUrls();
  const { goToProductionEnv: goToProductionUrlSender } =
    useCareseekerHandlerUrls();

  const { token } = useParams<{ token?: string }>();
  const isActivation = getQueryBooleanVariable(location.search, "activation");

  const [verifyTokenProgress, email, tokenInvalidReason] = useVerifyToken({
    token,
    isActivation,
  });
  const [loading, setLoading] = useState<QueryProgressActive>(
    QUERY_PROGRESS_NOT_STARTED,
  );

  const handleSubmit = useCallback(
    async ({ new_password }: { new_password: string }) => {
      if (!token || !email) return;
      try {
        setLoading(QUERY_PROGRESS_PENDING);
        const loginArgs = await getLoginArguments({
          username: email,
          password: new_password,
        });
        await api.auth.changePassword({
          password_hashed: loginArgs.password_hashed,
          salt: loginArgs.salt,
          timestamp: getTimestamp(locale),
          token,
          user: email,
        });
        trackEvent({
          name: isActivation
            ? TRACK_EVENTS.ACCOUNT_ACTIVATION_SUCCEEDED
            : TRACK_EVENTS.PASSWORD_CHANGE_SUCCEEDED,
        });
        setView("success");
      } catch (err) {
        console.error(err);
        trackEvent({
          name: isActivation
            ? TRACK_EVENTS.ACCOUNT_ACTIVATION_FAILED
            : TRACK_EVENTS.PASSWORD_CHANGE_FAILED,
        });
      } finally {
        setLoading(QUERY_PROGRESS_NOT_STARTED);
      }
    },
    [token, locale, setLoading, navigate, toast],
  );

  return (
    <main>
      {isLoading(verifyTokenProgress) ? (
        // #TODO - Add new design for SpinnerPage with
        <SpinnerPageContainer>
          <SpinnerPage id="password-reset-page" />
        </SpinnerPageContainer>
      ) : tokenInvalidReason !== undefined ? (
        <TokenExpired
          app={app}
          authRoute={authRoute}
          isActivation={isActivation}
          tokenInvalidReason={tokenInvalidReason}
        />
      ) : isPasswordResetSuccess(view) ? (
        <PasswordChangeSuccessPage
          app={app as PasswordChangeSuccessPageProps["app"]}
          loginUrl="/logout?reason=password_reset"
          env={env}
        />
      ) : (
        <SimpleFormRenderProps
          asHtmlForm
          modelDefinition={modelDefinition}
          onSubmit={handleSubmit}
          validate={validatePassword}
        >
          {({ submit }) => (
            <PasswordResetFromEmailPage
              app={app as PasswordResetFromEmailPageProps["app"]}
              env={env}
              goToProductionUrl={
                isSenderApp(app)
                  ? goToProductionUrlSender()
                  : goToProductionUrlReceiver()
              }
              isLoading={isLoading(loading)}
              onSubmit={submit}
            />
          )}
        </SimpleFormRenderProps>
      )}
    </main>
  );
}
