import { WHITE } from "ds_legacy/materials/colors";
import { Divider } from "ds_legacy/materials/layouts";
import { dp, Z_INDEX_MAX } from "ds_legacy/materials/metrics";
import React from "react";
import styled from "styled-components";
import Step from "./Step";
import { isDesignBatch1 } from "core/model/utils/featureFlags";

export const STEP_MENU_HEIGHT = 38;
const STEP_WIDTH = 130;
const DOT_WIDTH = 2;
const DOT_BORDER_SIZE = 1;

const StepperContainer = styled.div<{ fixed?: boolean; fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${dp(STEP_MENU_HEIGHT)};
  width: ${(props) => (props.fullWidth ? "100%" : undefined)};
  box-sizing: border-box;
  position: ${(props) => (props.fixed ? "fixed" : undefined)};
  z-index: ${(props) => (props.fixed ? Z_INDEX_MAX : undefined)};
  background: ${(props) => (props.fixed ? WHITE : undefined)};
  cursor: default;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const StepContainer = styled.div<{ stepWidth?: number }>`
  min-width: ${(props) => dp(props.stepWidth ? props.stepWidth : STEP_WIDTH)};
  text-align: center;
`;

const Progress = styled(Divider)`
  position: relative;
  height: 2px;
  background-color: ${(props) => props.theme.palette.primary.main};
  opacity: 1;
  margin-top: -2px;
`;

export const StepMenuContainer = styled.div<{
  center?: boolean;
  navbarHeight?: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.center ? "center" : undefined)};
  width: 100%;
  box-sizing: border-box;
  margin-top: ${({ navbarHeight }) =>
    isDesignBatch1() ? dp(navbarHeight ?? 0) : ""};
  @media print {
    display: none;
  }
`;

export const MIN_STEP_WIDTH = 146;

export function normalizeStepWidth(
  refs: Array<any>,
  stepWidth: number | undefined,
) {
  if (refs.length === 0 || !refs[0]) return;
  const widths: Array<number> = [];
  const minWidth = stepWidth
    ? Math.min(stepWidth, MIN_STEP_WIDTH)
    : MIN_STEP_WIDTH;
  refs.forEach((ref) => widths.push(ref.getBoundingClientRect().width));
  const maxWidth = Math.max.apply(null, widths);
  let normalizedWidth = Math.ceil(maxWidth);
  const isEven = normalizedWidth % 2 == 0;
  if (!isEven) normalizedWidth += 1;
  if (normalizedWidth < minWidth) normalizedWidth = minWidth;
  // eslint-disable-next-line no-param-reassign
  refs.forEach((ref) => (ref.style.width = dp(normalizedWidth)));
}

export default function StepMenu<T extends React.ReactNode>({
  activeStep,
  fixed,
  fullWidth,
  progress,
  renderStep,
  steps,
  stepWidth,
}: {
  activeStep: number;
  fixed?: boolean;
  fullWidth?: boolean;
  progress?: boolean;
  renderStep?: (step: T, currentStep: number) => React.ReactNode;
  stepWidth?: number;
  steps: Array<T>;
}) {
  const refs: Array<any> = [];

  const adjustedActiveStep = Math.max(
    0,
    Math.min(activeStep, steps.length - 1),
  );

  return (
    <>
      <StepperContainer
        fixed={fixed}
        data-testid="step-menu"
        fullWidth={fullWidth}
      >
        <StepsContainer>
          {steps.reduce((acc: Array<React.ReactNode>, step, index) => {
            acc.push(
              <StepContainer
                key={acc.length}
                ref={(node) => (refs[index] = node)}
                stepWidth={stepWidth}
              >
                {renderStep ? (
                  renderStep(step, index)
                ) : (
                  <Step
                    active={index === adjustedActiveStep}
                    done={index < adjustedActiveStep}
                  >
                    {step}
                  </Step>
                )}
              </StepContainer>,
            );
            return acc;
          }, [])}
        </StepsContainer>
        <Divider />
        {progress ? (
          <Progress
            ref={(node) => {
              normalizeStepWidth(refs, stepWidth);

              if (node && refs.length > 0) {
                const stepsWidth = refs.reduce((acc, ref, index) => {
                  if (index < adjustedActiveStep) {
                    return acc + ref.offsetWidth;
                  } else if (index === adjustedActiveStep) {
                    return acc + ref.offsetWidth / 2;
                  }
                  return acc;
                }, 0);
                const dotsWidth =
                  adjustedActiveStep * (DOT_WIDTH + DOT_BORDER_SIZE * 2);
                // eslint-disable-next-line no-param-reassign
                node.style.width = dp(dotsWidth + stepsWidth);
              }
            }}
          />
        ) : (
          <Progress
            ref={(node) => {
              normalizeStepWidth(refs, stepWidth);

              if (node && refs.length > 0) {
                const left = refs.reduce((acc, ref, index) => {
                  if (index < adjustedActiveStep) {
                    return acc + ref.offsetWidth;
                  }
                  return acc;
                }, 0);

                const stepsWidth = refs[adjustedActiveStep].offsetWidth;
                const dotsWidth =
                  adjustedActiveStep * (DOT_WIDTH + DOT_BORDER_SIZE * 2);
                // eslint-disable-next-line no-param-reassign
                node.style.width = dp(stepsWidth);
                // eslint-disable-next-line no-param-reassign
                node.style.left = dp(dotsWidth + left);
              }
            }}
          />
        )}
      </StepperContainer>
      {fixed ? <div style={{ height: dp(STEP_MENU_HEIGHT) }}></div> : null}
    </>
  );
}
