import {
  APP_ACP,
  APP_CAREPROVIDER,
  APP_CLINIC,
  APP_PROVIDER_SEARCH,
} from "core/consts";
import Config, {
  ENV_DEMO,
  ENV_DEVELOPMENT,
  ENV_PREPROD,
  ENV_PRODUCTION,
  ENV_STAGING,
} from "core/model/config";
import { AppType, CyFeatureFlagKey, CyFeatureFlags } from "core/types";

declare global {
  interface Window {
    Cypress: unknown;
    IN_STORYBOOK?: boolean;
    flags?: Partial<CyFeatureFlags>;
  }
}

export function cy(id: CyFeatureFlagKey, value: boolean): boolean {
  if (window.Cypress && window.flags?.[id] != null) {
    return !!window.flags[id];
  }
  return value;
}

export const isCI = (() => {
  if (window) return Boolean(window.Cypress);
  return false;
})();

export const isTest = process.env.NODE_ENV === "test" || window.IN_STORYBOOK;

export const isStoryshots = /StoryShotsVisualTesting/.test(navigator.userAgent);

// check if app is running embedded in an iframe, e.g. Korian integration
// don't rely on useQueryParam to work outside router and not polute global search params
export const isAppEmbedded = () =>
  typeof location !== "undefined" && location.search.includes("embedded");

export const isDev = process.env.ENV === ENV_DEVELOPMENT;

export const isPreprod = process.env.ENV === ENV_PREPROD;

export const activateEnvSwitch =
  !isCI && (isDev || process.env.ENV === ENV_STAGING);

export const isProd =
  Config.environment === ENV_PRODUCTION || Config.environment === ENV_DEMO;

export const isDemo = Config.environment === ENV_DEMO;

export const isStaging = !isProd && Config.environment !== ENV_PREPROD;

export const flagPrivateKey50K = Config.environment !== ENV_PRODUCTION;

export const activateBeamer = () =>
  !isAppEmbedded() &&
  Config.environment !== ENV_DEVELOPMENT &&
  Config.environment !== ENV_STAGING;

export const activateLoggingTracking =
  Config.environment !== ENV_DEVELOPMENT && Config.environment !== ENV_STAGING;

/*
  SenderDashboard
*/

export const activateSelectableProviders = () => {
  return cy("activateSelectableProviders", false);
};

export const activatePWA = !isProd;
export const activateBrowserNotification = !isProd;

export const activateWebSocket = (app: AppType) =>
  cy(
    "activateWebSocket",
    !isCI &&
      (
        [APP_CAREPROVIDER, APP_CLINIC, APP_PROVIDER_SEARCH] as AppType[]
      ).includes(app),
  );

export const activateRealUserMonitoring = isPreprod || isProd;

export const activateSeald = (app: number | null) => app !== APP_ACP;
export const activateSealdGroups = (app: number | null) => activateSeald(app);
export const activateSealdSessionEncryption = (app: number | null) =>
  activateSeald(app) && activateSealdGroups(app);

export const activateAcpUserManagement = true;
export const activateCareseekerUserManagement = true;

export const activateDTA = () => isStaging;
export const activateVersionRefreshBanner = !isProd;

export const activateProviderSearchTableOfContents = false;

export const activatePrintE2ETesting = () => isCI;

export const isDesignBatch1 = () => cy("isDesignBatch1", !isCI && !isProd);
