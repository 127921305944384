import { activatePrintE2ETesting } from "core/model/utils/featureFlags";
import { Title } from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import styled from "styled-components";
import { useTranslations } from "translations";

const PrintOverlayContainer = styled.div`
  display: flex;
  flex: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 1;
  z-index: 100;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media print {
    opacity: 0;
  }
`;

export default function PrintOverlay() {
  const translations = useTranslations();
  const print = usePrint();

  if (!print || activatePrintE2ETesting()) return null;

  return (
    <PrintOverlayContainer>
      <Title bold>{translations.general.printingPleaseWait}</Title>
    </PrintOverlayContainer>
  );
}
