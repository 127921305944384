import { Image } from "@nextui-org/react";
import { useEnvContext } from "context/EnvContext";
import { APP_CLINIC, DOCUMENT_PRIVACY_POLICY } from "core/consts";
import {
  ENV_DEMO,
  getStaticAsset,
  isReceiverApp,
  isSenderApp,
} from "core/model/config";
import { isDesignBatch1 } from "core/model/utils/featureFlags";
import { Banner, Button, ConnectedPasswordInput, Link } from "ds/ui";
import { useExternalLinkLabel } from "ds_legacy/components/LinkV2";
import { getRecareLogoWithNamePath } from "ds_legacy/components/RecareLogo";
import { useLegalDocuments } from "dsl/atoms/LegalDocuments";
import { useNavbarHeightContext } from "dsl/atoms/Navbar/Context/NavbarHeightContext";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useOnEnter } from "dsl/atoms/Routes";
import {
  loginPage,
  LoginPageVariants,
} from "dsl/ecosystems/LoginPageV2/classes";
import {
  useAcpHandlerUrls,
  useCareproviderHandlerUrls,
  useCareseekerHandlerUrls,
} from "dsl/hooks/useNavigationHandlers";
import { validatePassword } from "dsl/organisms/PasswordResetPageV2";
import {
  composeValidation,
  convertModelDefinition,
  SimpleFormRenderProps,
  ValidationFunction,
  valueDef,
} from "react-forms-state/src";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { PasswordChangeSuccessPage } from "./PasswordChangeSuccessPage";
import {
  isPasswordChangeDefaultView,
  PasswordChangeErrorState,
  useChangePassword,
} from "./useChangePassword";

export type PasswordChangePageProps = LoginPageVariants & {
  errors?: PasswordChangeErrorState;
  goToDashboardUrl: string;
  goToProductionUrl: string;
  isLoading: boolean;
  onSubmit: () => void;
  resetError: () => void;
};

export const validateCurrentPassword: ValidationFunction = (
  { current_password }: { current_password: string | null },
  { translations }: { translations: Translations },
): true | Partial<Record<"current_password", string>> => {
  let validation: true | Partial<Record<"current_password", string>> = true;

  // Check if the password is missing
  if (!current_password) {
    validation = {
      current_password: translations.login.loginPage.currentPasswordValidation,
    };
  }

  return validation;
};

// unlinke other parts in the app, this page is not wrapped in a <ScrollableContent>,
// so we need to push the content down with this helper block
const EmptyBlock = ({ navbarHeight }: { navbarHeight: number }) => {
  return <div className="z-0 w-full" style={{ height: `${navbarHeight}px` }} />;
};

export function PasswordChangePage({
  app,
  env,
  errors,
  goToDashboardUrl,
  goToProductionUrl,
  isLoading,
  onSubmit,
  resetError,
}: PasswordChangePageProps) {
  const isDemo = env === ENV_DEMO;
  const classes = loginPage({ app, env });
  const { isMobile } = useMedia();
  const translations = useTranslations();
  const { getDocumentUrl } = useLegalDocuments();
  const externalLinkLabel = useExternalLinkLabel();
  const { height } = useNavbarHeightContext();

  return (
    <div className={classes.page()}>
      {app === APP_CLINIC && isDesignBatch1() && (
        <EmptyBlock navbarHeight={height} />
      )}
      <div className={classes.container()}>
        <div className={classes.content()}>
          <div className={classes.header()}>
            <Image
              width={160}
              alt="Recare logo"
              src={getStaticAsset(getRecareLogoWithNamePath("master"))}
            />
          </div>
          <div className={classes.form()}>
            <div className={classes.formContent()}>
              <h1 className={classes.title()}>
                {isDemo && (
                  <span className="font-normal">
                    {translations.login.loginPage.titleDemo} |{" "}
                  </span>
                )}
                {translations.login.loginPage.titleChangePassword}
              </h1>
              <p className="text-sm" id="description">
                {translations.login.setNewPasswordInstructions}
              </p>
              {isDemo && (
                <Banner
                  color="warning"
                  size="sm"
                  message={
                    <p>
                      {translations.login.loginPage.environmentInfo}{" "}
                      <Link
                        id="go-to-prod"
                        aria-label={externalLinkLabel(
                          translations.login.loginPage.productionLink,
                          "website",
                        )}
                        color="primary-dark"
                        href={goToProductionUrl}
                        size="sm"
                      >
                        {translations.login.loginPage.productionLink}
                      </Link>
                      .
                    </p>
                  }
                />
              )}
            </div>
            <div className={classes.formContent()}>
              {errors?.general && (
                <Banner
                  color="danger"
                  data-testid="password-change-error"
                  message={errors.general}
                  role="alert"
                />
              )}
              <ConnectedPasswordInput
                elementName="current_password"
                errorMessage={errors?.current_password}
                isInvalid={!!errors?.current_password}
                label={translations.login.currentPassword}
                placeholder={translations.login.loginPage.passwordPlaceholder}
                required
                sideMutation={() => resetError()}
              />
              <ConnectedPasswordInput
                aria-describedby="description"
                elementName="new_password"
                label={translations.login.enterNewPasswordTitle}
                placeholder={translations.login.enterNewPasswordPlaceholder}
                required
                sideMutation={() => resetError()}
                withIndicator
              />
              <ConnectedPasswordInput
                elementName="confirm_new_password"
                label={translations.login.confirmYourPasswordTitle}
                placeholder={translations.login.confirmNewPasswordPlaceholder}
                required
                sideMutation={() => resetError()}
              />
            </div>
            <div className="flex flex-col-reverse items-center justify-between gap-y-2 tablet:flex-row">
              <Link
                id="go-to-dashboard"
                color="primary-dark"
                href={goToDashboardUrl}
              >
                {translations.login.loginPage.toDashboardLink}
              </Link>
              <Button
                color="primary"
                fullWidth={isMobile}
                id="save-new-password"
                isLoading={isLoading}
                onPress={onSubmit}
                size="lg"
                type="submit"
                variant="solid"
              >
                {translations.login.loginPage.saveNewPasswordButton}
              </Button>
            </div>
            <Banner
              color="warning"
              size="sm"
              message={translations.login.setNewPasswordEncryptionInformation}
            />
          </div>
        </div>
        <div className={classes.footer()}>
          <hr className={classes.footerDivider()} />
          <p>
            {translations.login.loginPage.privacyPolicyInfo}{" "}
            <Link
              id="privacy-policy"
              aria-label={externalLinkLabel(
                translations.login.privacyPolicy,
                "pdf",
              )}
              href={getDocumentUrl(DOCUMENT_PRIVACY_POLICY)}
              size="sm"
              target="_blank"
              color="primary-dark"
            >
              {translations.login.privacyPolicy}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default function ConnectedPasswordChangePage() {
  useOnEnter();
  const { app, env } = useEnvContext();
  const { changePasswordView, errors, handleSubmit, isLoading, resetError } =
    useChangePassword();
  const { goToDashboard, goToProductionEnv: goToProductionEnvSender } =
    useCareseekerHandlerUrls();
  const { goToProductionEnv: goToProductionEnvReceiver, goToRequestDashboard } =
    useCareproviderHandlerUrls();
  const {
    goToDashboard: goToDashboardAcp,
    goToProductionEnv: goToProductionEnvAcp,
  } = useAcpHandlerUrls();

  return (
    <main>
      {isPasswordChangeDefaultView(changePasswordView) ? (
        <SimpleFormRenderProps
          asHtmlForm
          onSubmit={handleSubmit}
          modelDefinition={convertModelDefinition({
            ...valueDef("current_password"),
            ...valueDef("new_password"),
            ...valueDef("confirm_new_password"),
          })}
          validate={composeValidation(
            validatePassword,
            validateCurrentPassword,
          )}
        >
          {({ submit }) => (
            <PasswordChangePage
              app={app as LoginPageVariants["app"]}
              env={env}
              errors={errors}
              goToDashboardUrl={
                isSenderApp(app)
                  ? goToDashboard()
                  : isReceiverApp(app)
                  ? goToRequestDashboard()
                  : goToDashboardAcp()
              }
              goToProductionUrl={
                isSenderApp(app)
                  ? goToProductionEnvSender()
                  : isReceiverApp(app)
                  ? goToProductionEnvReceiver()
                  : goToProductionEnvAcp()
              }
              isLoading={isLoading}
              onSubmit={submit}
              resetError={resetError}
            />
          )}
        </SimpleFormRenderProps>
      ) : (
        <PasswordChangeSuccessPage
          app={app as LoginPageVariants["app"]}
          env={env}
          loginUrl="/logout?reason=password_change"
        />
      )}
    </main>
  );
}
