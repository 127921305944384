import { useTheme } from "@mui/material";
import { composeProgress } from "apollo/utils";
import { useEnvContext } from "context/EnvContext";
import {
  APP_CLINIC,
  DOCUMENT_PRIVACY_POLICY,
  QUERY_PROGRESS_PENDING,
  TOKEN_INVALID_REASONS,
} from "core/consts";
import { getQueryBooleanVariable } from "core/model/utils/urls";
import { AppType, QueryProgressActive, TokenInvalidReason } from "core/types";
import InfoCaption from "ds_legacy/components/InfoCaption";
import LinkV2 from "ds_legacy/components/LinkV2";
import RSButton from "ds_legacy/components/RSButton";
import { RecareLogoOnly } from "ds_legacy/components/RecareLogo";
import { SpinnerPage } from "ds_legacy/components/Spinner";
import StrengthIndicator from "ds_legacy/components/StrengthIndicator";
import { TextInputField } from "ds_legacy/components/TextInputField";
import { ERROR_COLOR } from "ds_legacy/materials/colors";
import { dp, margin, sizing } from "ds_legacy/materials/metrics";
import { Body, Caption, Subheading } from "ds_legacy/materials/typography";
import { useLegalDocuments } from "dsl/atoms/LegalDocuments";
import { useOnEnter } from "dsl/atoms/Routes";
import { useOnboarding } from "dsl/ecosystems/CareproviderOnboarding";
import {
  Footer,
  FooterWrapper,
  Header,
  HeaderWrapper,
  LockCenter,
  OnboardingWidget,
  Overlay,
  StyledForm,
  Widget,
} from "dsl/ecosystems/CareproviderOnboardingLoginPage";
import {
  BottomWrapper,
  FlexContainer,
  InputFieldContainer,
} from "dsl/ecosystems/CareproviderOnboardingLoginPage/shared";
import usePasswordStrength from "dsl/hooks/usePasswordStrength";
import { CircleAlertIcon } from "lucide-react";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { useChangePassword } from "./useChangePassword";
import { FormTypeValidation, useValidatePassword } from "./useValidatePassword";
import { useVerifyToken } from "./useVerifyToken";

export type PasswordResetProps = {
  changePassword: () => Promise<void>;
  isActivation?: boolean;
  loading: QueryProgressActive;
  password: string | undefined;
  passwordConfirmation: string | undefined;
  setPassword: Dispatch<SetStateAction<string | undefined>>;
  setPasswordConfirmation: Dispatch<SetStateAction<string | undefined>>;
  toAuth: () => void;
  tokenInvalidReason: TokenInvalidReason | undefined;
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: ${sizing(9)};
  width: ${sizing(27.5)};
`;

export const getChecks = (translations: Translations): FormTypeValidation[] => [
  {
    key: "length",
    description: translations.login.passwordValidationLength,
    check: /^(?=.{8,})/,
  },
  {
    key: "numeric",
    description: translations.login.passwordValidationNumeric,
    check: /^(?=.*[0-9])/,
  },
  {
    key: "lower-case",
    description: translations.login.passwordValidationLowerCase,
    check: /^(?=.*[a-z])/,
  },
  {
    key: "upper-case",
    description: translations.login.passwordValidationUpperCase,
    check: /^(?=.*[A-Z])/,
  },
];

export const getTokenErrorTitle = (
  translations: Translations,
  tokenInvalidReason: TokenInvalidReason | undefined,
): string => {
  switch (tokenInvalidReason) {
    case TOKEN_INVALID_REASONS.EXPIRED:
      return translations.login.linkExpired.linkExpiredTitle;
    case TOKEN_INVALID_REASONS.PASSWORD_CHANGED:
      return translations.login.passwordChanged;
    case TOKEN_INVALID_REASONS.INVALID:
      return translations.login.tokenInvalid;
    default:
      return translations.login.tokenInvalid;
  }
};

export function useGetTokenErrorText({
  app,
  isActivation,
  tokenInvalidReason,
}: {
  app: AppType;
  isActivation: boolean | undefined;
  tokenInvalidReason: TokenInvalidReason | undefined;
}) {
  const translations = useTranslations();

  if (tokenInvalidReason !== TOKEN_INVALID_REASONS.EXPIRED) return null;

  switch (app) {
    case APP_CLINIC:
      return isActivation
        ? translations.login.linkExpired.activationExpiredSender
        : translations.login.linkExpired.passwordLinkExpiredSender;

    default:
      return isActivation
        ? translations.login.linkExpired.activationExpiredReceiver
        : translations.login.linkExpired.passwordLinkExpiredReceiver;
  }
}

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: ${ERROR_COLOR};
  & > * + * {
    margin-top: ${margin(1)};
  }
`;

export function PasswordReset({
  changePassword,
  isActivation,
  loading,
  password,
  passwordConfirmation,
  setPassword,
  setPasswordConfirmation,
  validations,
}: {
  changePassword: () => Promise<void>;
  isActivation?: boolean;
  loading: QueryProgressActive;
  password: string | undefined;
  passwordConfirmation: string | undefined;
  setPassword: Dispatch<SetStateAction<string | undefined>>;
  setPasswordConfirmation: Dispatch<SetStateAction<string | undefined>>;
  validations: FormTypeValidation[];
}) {
  const translations = useTranslations();
  const { strength, track } = usePasswordStrength(password);

  if (loading === QUERY_PROGRESS_PENDING)
    return <SpinnerPage id="password-reset-page" />;

  return (
    <Fragment>
      <StyledForm
        style={{ height: "100%" }}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          if (
            !password ||
            !passwordConfirmation ||
            password !== passwordConfirmation ||
            !!(validations && validations.length > 0)
          ) {
            return;
          }

          changePassword().then(() => track());
        }}
      >
        <InputFieldContainer>
          <TextInputField
            autoFocus
            onChange={setPassword}
            autoComplete="current-password"
            label={translations.login.newPassword}
            required
            textInputType="password"
            elementName="password"
            value={password}
          />
        </InputFieldContainer>
        <StrengthIndicator
          hide={!password}
          strength={strength}
          translations={translations}
        />
        <InputFieldContainer>
          <TextInputField
            onChange={setPasswordConfirmation}
            autoComplete="current-password"
            label={translations.login.newPasswordConfirmation}
            required
            validateOverride={
              passwordConfirmation !== password
                ? translations.login.passwordsMustMatch
                : ""
            }
            textInputType="password"
            elementName="password-confirmation"
            value={passwordConfirmation}
          />
        </InputFieldContainer>

        {validations && validations.length > 0 && (
          <Fragment>
            <Body>{translations.login.passwordRequirements}</Body>
            {validations.map((val) => (
              <Caption key={val.key}>{val.description}</Caption>
            ))}
          </Fragment>
        )}

        <BottomWrapper>
          <FlexContainer>
            <RSButton
              color="primary"
              id="change_password"
              loading="na"
              onClick={() => {}}
              variant="contained"
              type="submit"
            >
              {isActivation
                ? translations.actions.save
                : translations.login.changePassword}
            </RSButton>
          </FlexContainer>
          <FlexContainer>
            <InfoCaption margin={margin(3, 3, 3, 5)}>
              {translations.login.hintTemporaryLossOfEncryptedData}
            </InfoCaption>
          </FlexContainer>
        </BottomWrapper>
      </StyledForm>
    </Fragment>
  );
}

export function TokenErrorView({
  isActivation,
  toAuth,
  tokenInvalidReason,
}: Pick<PasswordResetProps, "isActivation" | "toAuth" | "tokenInvalidReason">) {
  const { app } = useEnvContext();
  const translations = useTranslations();

  const tokenErrorDescription = useGetTokenErrorText({
    tokenInvalidReason,
    app,
    isActivation,
  });

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "flex-start",
        marginTop: dp(100),
      }}
    >
      <ErrorContainer>
        <CircleAlertIcon size={40} />
        <Body error as="h2" margin={margin(1)}>
          {getTokenErrorTitle(translations, tokenInvalidReason)}
        </Body>
        {tokenErrorDescription && <Body as="p">{tokenErrorDescription}</Body>}
        <ButtonWrapper>
          <RSButton
            color="primary"
            id="login"
            loading="na"
            onClick={() => toAuth()}
            style={{ width: "100%", margin: 0 }}
            variant="contained"
          >
            {translations.login.login}
          </RSButton>
        </ButtonWrapper>
      </ErrorContainer>
    </div>
  );
}

export function PasswordResetView({
  changePassword,
  isActivation,
  loading,
  password,
  passwordConfirmation,
  setPassword,
  setPasswordConfirmation,
  toAuth,
  tokenInvalidReason,
}: PasswordResetProps) {
  const translations = useTranslations();
  const [validations, setValidations] = useState<FormTypeValidation[]>([]);

  useValidatePassword({ password, passwordConfirmation, setValidations });
  const { getDocumentUrl } = useLegalDocuments();
  const theme = useTheme();

  return (
    <>
      <Header>
        <HeaderWrapper>
          <Subheading light>
            {isActivation
              ? translations.login.newPassword.toUpperCase()
              : translations.login.changePassword.toUpperCase()}
          </Subheading>
          <RecareLogoOnly />
        </HeaderWrapper>
      </Header>
      {tokenInvalidReason === undefined ? (
        <PasswordReset
          isActivation={isActivation}
          changePassword={changePassword}
          password={password}
          setPassword={setPassword}
          passwordConfirmation={passwordConfirmation}
          setPasswordConfirmation={setPasswordConfirmation}
          validations={validations}
          loading={loading}
        />
      ) : (
        <TokenErrorView
          toAuth={toAuth}
          tokenInvalidReason={tokenInvalidReason}
          isActivation={isActivation}
        />
      )}

      <FooterWrapper>
        <Footer>
          <LinkV2
            href={getDocumentUrl(DOCUMENT_PRIVACY_POLICY)}
            color={theme.palette.common.black}
          >
            {translations.login.privacyPolicy}
          </LinkV2>
        </Footer>
      </FooterWrapper>
    </>
  );
}

export default function PasswordResetPage({
  authRoute,
}: {
  authRoute: string;
}) {
  useOnEnter();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
  const { token } = useParams<{ token?: string }>(); // Getting the token for the Password Reset Page, if user didn't trigger it from Onboarding
  const { careproviderToken, passwordToken, resetPasswordPage } =
    useOnboarding();
  const resetToken = careproviderToken ? passwordToken : token;
  const isActivation = getQueryBooleanVariable(location.search, "activation");

  function toAuth() {
    return navigate(authRoute, { replace: true });
  }

  const [verifyTokenProgress, email, tokenInvalidReason] = useVerifyToken({
    token: resetToken,
    isActivation,
  });

  const [changePasswordProgress, changePassword] = useChangePassword({
    careproviderToken,
    email,
    password,
    resetPasswordPage,
    resetToken,
    isActivation,
  });

  function renderPasswordResetView() {
    return (
      <PasswordResetView
        changePassword={changePassword}
        isActivation={isActivation}
        loading={composeProgress(
          [changePasswordProgress, verifyTokenProgress],
          true,
        )}
        password={password}
        passwordConfirmation={passwordConfirmation}
        setPassword={setPassword}
        setPasswordConfirmation={setPasswordConfirmation}
        toAuth={toAuth}
        tokenInvalidReason={tokenInvalidReason}
      />
    );
  }

  if (careproviderToken) {
    return <OnboardingWidget>{renderPasswordResetView()}</OnboardingWidget>;
  }

  return (
    <div>
      <Overlay>
        <LockCenter>
          <Widget height={dp(530)}>{renderPasswordResetView()}</Widget>
        </LockCenter>
      </Overlay>
    </div>
  );
}
